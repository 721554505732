import get from "lodash.get";
import kebabCase from "lodash.kebabcase";
import upperFirst from "lodash.upperfirst";
import { FETCH_FULFILLMENT_PRODUCT_VARIANTS } from "../../graphql/mutation";
import { invalidArr, invalidOb } from "../../utils";

export const getCombineVariants = (attributes, variants) => {
  if (!attributes.length) {
    return [];
  }
  let res = [];

  const n = attributes.length;
  let indices = [];
  // fill zero
  for (let i = 0; i < n; i++) {
    indices[i] = 0;
  }

  while (true) {
    let options = [];
    let attrs = [];
    for (let i = 0; i < n; i++) {
      const option = attributes[i].options[indices[i]];
      options.push(option);
      attrs.push({
        name: attributes[i].name,
        slug: attributes[i].slug,
        option: option,
      });
    }
    res.push({
      __slug: options.join("-"),
      attributes: attrs,
      regularPrice: 0,
      salePrice: 0,
      baseCost: 0,
      fulfillmentProductId: null,
    });

    let next = n - 1;
    while (next >= 0 && indices[next] + 1 >= attributes[next].options.length) {
      next--;
    }
    if (next < 0) {
      break;
    }
    indices[next]++;
    for (let i = next + 1; i < n; i++) {
      indices[i] = 0;
    }
  }

  let mapVariantBySlug = {};
  if (!variants) {
    variants = [];
  }
  for (let i = 0; i < variants.length; i++) {
    const v = variants[i];
    if (v.__slug) {
      mapVariantBySlug[v.__slug] = v;
    } else {
      let options = [];
      for (let j = 0; j < v.attributes.length; j++) {
        const attr = v.attributes[j];
        options.push(attr.option);
      }
      const slug = options.join("-");
      mapVariantBySlug[slug] = v;
    }
  }
  // handle take exist value from prev variants
  for (let i = 0; i < res.length; i++) {
    const v = res[i];
    const prevVariant = mapVariantBySlug[v.__slug];
    if (prevVariant) {
      // res[i] = prevVariant;
      res[i] = { ...prevVariant, attributes: v.attributes }; // Update attributes
    }
    if (typeof res[i].__slug !== "undefined") {
      delete res[i].__slug;
    }
  }
  return res.map((item, idx) => ({ ...item, sorting: idx }));
};

export const getVariantName = (item) => {
  let options = item.attributes.map((attr) => attr.option);
  return options.join("/");
};

const formatFloat = (n) => {
  return isNaN(n) ? 0 : parseFloat(n);
};

// **** FORMAT INPUT ******
export const invalidFFProductId = (arr) => {
  if (invalidArr(arr)) return false;

  return arr.some(
    ({ fulfillmentProductId }) =>
      fulfillmentProductId == null || fulfillmentProductId === "",
  );
};

// Attributes
export const formatInputAttrs = (arr) => {
  if (invalidArr(arr)) return arr;

  return arr.map((item) => {
    const { options, __typename, ...rest } = item || {};
    return {
      ...rest,
      option: options,
    };
  });
};

// Design positions
export const formatInputDS = (arr) => {
  if (invalidArr(arr)) return arr;
  return arr.map((item) => {
    const { id, image, description, name, productBaseVariants } = item || {};
    let imageId = image ? image.id : undefined;
    let baseVars;
    if (!invalidArr(productBaseVariants)) {
      baseVars = productBaseVariants.map((item) => {
        const { attributes } = item || {};
        let newAttrs;
        if (!invalidArr(attributes)) {
          newAttrs = compactAttrs(attributes);
        }

        return {
          attributes: newAttrs,
        };
      });
    }

    return {
      id,
      name,
      description,
      imageId,
      productBaseVariants: baseVars,
    };
  });
};

// Variants
const compactAttrs = (arr) => {
  if (invalidArr(arr)) return arr;
  return arr.map((item) => {
    const { __typename, ...rest } = item;
    return rest;
  });
};

export const formatInputVars = (arr) => {
  if (invalidArr(arr)) return arr;

  return arr
    .map((item) => {
      if (invalidOb(item)) return null;
      const { sorting, productBaseId, __typename, ...rest } = item;
      const newAttr = compactAttrs(item.attributes);

      return {
        ...rest,
        baseCost: formatFloat(item.baseCost),
        regularPrice: formatFloat(item.regularPrice),
        salePrice: formatFloat(item.salePrice),
        attributes: newAttr,
        fulfillmentProductId: item.fulfillmentProductId + "",
      };
    })
    .filter(Boolean);
};

// **** FORM VALUES ******
export const formatValAttrs = (arr) => {
  if (invalidArr(arr)) return arr;

  return arr.map((item) => {
    const { option, ...rest } = item;
    return {
      ...rest,
      options: option,
    };
  });
};

// === FETCH ===
export async function fetchFulfillmentProductVariants(
  productId,
  fulfillmentId,
) {
  if (invalidOb(window.__psn_client__) || !productId || !fulfillmentId)
    return {};

  try {
    const data = await window.__psn_client__.mutate({
      mutation: FETCH_FULFILLMENT_PRODUCT_VARIANTS,
      variables: {
        productId,
        fulfillmentId,
      },
    });

    return data?.data?.fetchFulfillmentProductVariants;
  } catch (err) {}
  return;
}
// ===================== CUSTOMCAT =====================
export function formatDataForCC(data) {
  if (!data || typeof data !== "object") return {};
  const title = get(data, "product_name");
  const restInfo = getVariantsCC(data);

  return {
    title,
    ...restInfo,
  };
}

function getVariantsCC(data) {
  if (!data || typeof data !== "object") return {};

  let productColors = data.product_colors;
  if (!productColors || productColors.length === 0) return {};

  productColors = productColors.filter(({ skus }) => {
    if (!skus || skus.length === 0) return false;

    return skus.some(({ in_stock }) => in_stock === 1); // != 1 => `out_of_stock`
  });

  if (!productColors || productColors.length === 0) return {};

  let variants = [];
  const mergeAttributes = new Map();
  const mergeDS = new Map(); // DesignPosition
  let sorting = 0;
  for (let val of productColors) {
    if (!val || typeof val !== "object") continue;

    const { color, skus } = val;

    if (!skus || skus.length === 0) continue;
    for (let sku of skus) {
      if (!sku || typeof sku !== "object") continue;

      const { catalog_sku_id, cost, mrsp, size, pallet, in_stock } = sku;
      if (in_stock !== 1) continue;

      let colorFormat = color?.trim();
      if (colorFormat.endsWith("/")) {
        colorFormat.substring(0, colorFormat.length - 1);
      }
      const attributes = [
        { name: "Color", slug: "color", option: colorFormat },
      ];
      attributes.push({
        name: "Size",
        slug: "size",
        option: size,
      });

      variants.push({
        attributes,
        regularPrice: parseFloat(mrsp),
        baseCost: parseFloat(cost),
        salePrice: 0,
        fulfillmentProductId: catalog_sku_id,
        sorting: sorting++,
      });

      getAttributesFF({ color: colorFormat, size: size }, mergeAttributes);

      getDesignPositionCC(pallet, mergeDS, attributes);
    }
  }

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = upperFirst(key);
      const options = Array.from(value.values());

      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  const varLen = (variants || []).length;
  const designPositions = Array.from(mergeDS.entries()).map(([key, value]) => {
    const [name, w, h, suff] = key.split(/-/);
    const description = `${w}x${h}${suff}|PNG|300DPI`;

    const val = Array.from(value.values());
    const len = (val || []).length;
    return {
      name,
      image: null,
      description,
      productBaseVariants: varLen !== len ? val : [],
    };
  });

  return {
    variants,
    attributes,
    designPositions,
  };
}

function getAttributesFF(obj, mergeVal) {
  if (!obj || typeof obj !== "object") return mergeVal;

  for (let o in obj) {
    const val = obj[o];
    if (!val) continue;

    const cur = mergeVal.get(o);
    if (cur) {
      mergeVal.set(o, cur.add(val));
      continue;
    }

    mergeVal.set(o, new Set([val]));
  }
}

function getDesignPositionCC(arr, mergeVal, attributes) {
  if (!arr || arr.length === 0) return mergeVal;
  for (let { pallet_height, pallet_width, product_view } of arr) {
    const str = pallet_width || pallet_height;
    const pt = /[a-z]+/i;
    const no_pt = /[+-]?([0-9]*[.])?[0-9]+/;

    const [suffix] = str.match(pt) || [];
    let newW = pallet_width;
    if (newW) {
      [newW] = newW.match(no_pt) || [];
    }

    let newH = pallet_height;
    if (newH) {
      [newH] = newH.match(no_pt) || [];
    }

    const key = [product_view, newW, newH, suffix].filter(Boolean).join("-");

    const item = { id: undefined, attributes };
    const cur = mergeVal.get(key);
    if (cur) {
      mergeVal.set(key, cur.add(item));
    } else {
      mergeVal.set(key, new Set([item]));
    }
  }

  return mergeVal;
}

// ===================== GEARMENT =====================
export function formatProductsForGM(data) {
  if (invalidOb(data)) return {};

  const { product_name: title, variants: vars } = data;

  if (invalidArr(vars)) return {};
  const variants = [];
  const mergeAttributes = new Map();

  for (let i = 0; i < vars.length; i++) {
    const v = vars[i];
    const { variant_id: fulfillmentProductId, size, color, price } = v || {};
    const attributes = [
      {
        name: "Color",
        slug: "color",
        option: color,
      },
      {
        name: "Size",
        slug: "size",
        option: size,
      },
    ];

    variants.push({
      attributes,
      regularPrice: Math.max(price * 3, 0).toFixed(2),
      salePrice: 0,
      baseCost: Math.max(price, 0),
      sorting: i,
      fulfillmentProductId,
    });

    getAttributesFF({ color, size }, mergeAttributes);
  }

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = upperFirst(key);
      const options = Array.from(value.values());

      return {
        name,
        slug: kebabCase(key),
        options,
      };
    },
  );

  return {
    title,
    variants,
    attributes,
  };
}
