import { gql } from "@apollo/client";
import { FulfillmentFrag } from "./fulfillment";
import { StoreFrag } from "./store";
import { TrackingFrag } from "./tracking";

const AddressFrag = gql`
  fragment AddressFrag on Address {
    firstName
    lastName
    company
    phone
    address1
    address2
    city
    country
    state
    postalCode
  }
`;

const OrderFrag = gql`
  fragment OrderFrag on Order {
    id
    createdAt
    quantity
    price
    baseCost
    shippingCost
    revenue
    status
    originId
    lineItemId
    shippingOption
    paused
    fulfillmentStatus
    isManual
    platform
    note
    productStoreUrl
    store {
      ...StoreFrag
    }
    fulfillment {
      ...FulfillmentFrag
    }
    customer {
      email
    }
    shippingAddress {
      ...AddressFrag
    }
    productVariant {
      id
      productBaseVariant {
        id
        attributes {
          name
          option
        }
      }
    }
    product {
      id
      title
      sku
      personalized
      images {
        file {
          thumbnailUrl
          url
        }
      }
      productBases {
        id
        title
      }
    }
    fields {
      title
      value
    }
    tracking {
      ...TrackingFrag
    }
    fulfillmentOrder {
      id
    }
  }
  ${StoreFrag}
  ${FulfillmentFrag}
  ${AddressFrag}
  ${TrackingFrag}
`;

const OrderAggregationFrag = gql`
  fragment OrderAggregationFrag on OrderAggregation {
    all
    pendingDesign
    onHold
    inProduction
    fulfilled
    error
    cancelled
    designRejected
  }
`;

const OrderTimelineFrag = gql`
  fragment OrderTimelineFrag on OrderTimeline {
    orderId
    message
    createdAt
    author {
      id
      fullName
    }
  }
`;

const OrderDesignFrag = gql`
  fragment OrderDesignFrag on OrderDesign {
    id
    url
    file {
      id
      name
      url
      thumbnailUrl
    }
    designPosition {
      id
      name
      description
      sorting
    }
  }
`;

const MappingOrderFrag = gql`
  fragment MappingOrderFrag on MappingOrder {
    id
    createdAt
    store {
      id
      title
      platform
    }
    orderId
    lineItemId
    price
    product {
      id
      title
      sku
      images
      url
    }
    variant {
      id
      attrs {
        name
        value
      }
    }
    quantity
    personalized {
      title
      value
    }
    isPersonalized
    shippingService
  }
`;

export {
  OrderFrag,
  OrderAggregationFrag,
  OrderTimelineFrag,
  OrderDesignFrag,
  MappingOrderFrag,
};
