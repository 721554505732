const ROLES = {
  Admin: "Admin",
  Seller: "Seller",
  Designer: "Designer",
};

const Authenticated = "Authenticated";

const FULFILLMENT = {
  Customcat: {
    id: "4YO1M2F6RKTE9L",
    name: "Customcat",
    slug: "customcat",
  },
  Gearment: {
    id: "WA98HVZATEQV1K",
    name: "Gearment",
    slug: "gearment",
  },
  Custom: {
    id: "VYQQWZLABMM7QY",
    name: "Custom Fulfillment",
    slug: "custom-fulfillment",
  },
};

const STORE_STATUS = {
  Connected: "Connected",
  Suspended: "Suspended",
};

const PLATFORM = {
  Amazon: "Amazon",
  Etsy: "Etsy",
};

const IMAGE_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
];

const ORDER_STATUS = {
  pending: "Pending",
  pendingDesign: "Pending Design",
  onHold: "On Hold",
  inProduction: "In Production",
  fulfilled: "Fulfilled",
  error: "Error",
  cancelled: "Cancelled",
  designRejected: "Design Rejected",
};

const ORDER_STATUS_COLORS = {
  [ORDER_STATUS.pending]: "#239ccc",
  [ORDER_STATUS.pendingDesign]: "#709700",
  [ORDER_STATUS.onHold]: "blue",
  [ORDER_STATUS.inProduction]: "#dfdc42",
  [ORDER_STATUS.fulfilled]: "success",
  [ORDER_STATUS.error]: "error",
  [ORDER_STATUS.cancelled]: "orange",
  [ORDER_STATUS.designRejected]: "pink",
};


const TASK_STATUS = {
  pending: "Pending",
  doing: "Doing",
  needReview: "Need Review",
  rejected: "Rejected",
  done: "Done",
  cancel: "Cancel",
};

const TASK_SUBMISSION_STATUS = {
  Pending: "Pending",
  Accept: "Accept",
  Reject: "Reject",
};

export {
  Authenticated,
  ROLES,
  FULFILLMENT,
  STORE_STATUS,
  PLATFORM,
  IMAGE_TYPES,
  ORDER_STATUS,
  TASK_STATUS,
  TASK_SUBMISSION_STATUS,
  ORDER_STATUS_COLORS,
};
