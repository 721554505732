import { createContext, useContext, useMemo } from "react";

const TrackingContext = createContext();

export function TrackingProvider({ children, ...props }) {
  const bag = useMemo(() => ({ ...props }), [props]);

  return (
    <TrackingContext.Provider value={bag}>{children}</TrackingContext.Provider>
  );
}

export function useTrackingCtx() {
  return useContext(TrackingContext);
}

// History
const TrackingHistoryContext = createContext();

export function TrackingHistoryProvider({ children, ...props }) {
  const bag = useMemo(() => ({ ...props }), [props]);

  return (
    <TrackingHistoryContext.Provider value={bag}>
      {children}
    </TrackingHistoryContext.Provider>
  );
}

export function useTrackingHistoryCtx() {
  return useContext(TrackingHistoryContext);
}
