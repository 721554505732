import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React from "react";
import { ROLES } from "../../constants";
import { useAppContext } from "../../context";
import { REGISTER } from "../../graphql/mutation";
import FormWrap from "../shared/form-wrap";

const Register = () => {
  const { notification } = useAppContext();
  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted: () => {
      notification.success({ message: "Register successfully." });
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const onFinish = React.useCallback(
    (values) => {
      const { confirm, ...input } = values || {};
      register({
        variables: {
          input,
        },
      });
    },
    [register],
  );

  const roles = React.useMemo(() => {
    return Object.entries(ROLES).map(([label, value]) => ({ label, value }));
  }, []);

  return (
    <FormWrap>
      <Form
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        wrapperCol={{ style: { minWidth: 250 } }}
        initialValues={{
          roles: ROLES.Admin,
          remember: true,
        }}
      >
        <Typography.Title
          level={2}
          children=" Welcome to Personal"
          style={{ textAlign: "center" }}
        />
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="fullName"
              label="Fullname"
              rules={[
                {
                  required: true,
                  message: "Please input your Fullname",
                },
              ]}
            >
              <Input placeholder="Fullname" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                },
              ]}
            >
              <Input placeholder="Email" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your Password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password not match"),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="roles"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please choose role",
                },
              ]}
            >
              <Select options={roles} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item name="phone" label="Phone">
              <Input placeholder="Phone" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label="Address">
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>

        <Row align="middle" justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Register
            </Button>
            <Divider />
            <span>
              Or <a href="/login">login now!</a>
            </span>
          </Col>
        </Row>
      </Form>
    </FormWrap>
  );
};

export default Register;
