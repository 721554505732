import { PLATFORM, STORE_STATUS } from "../../constants";
import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd";
import React from "react";

export function StoreForm({
  onFinish,
  value,
  open,
  onCancel,
  loading,
  title,
  btnLabel,
}) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (value != null) {
      const { title, email, platform, status } = value;
      form.setFieldsValue({ title, email, platform, status });
    }
  }, [value, form]);

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: "16px 0px" }}
      forceRenderO
    >
      <Form
        name={value ? `${title}_${value.id}` : title}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ platform: PLATFORM.Amazon }}
      >
        <Form.Item
          name="title"
          label="Name"
          rules={genRules(
            [{ required: true, message: "Store name is required" }],
            !value,
          )}
        >
          <Input placeholder="Enter store name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={genRules(
            [{ required: true, message: "Store email is required" }],
            !value,
          )}
        >
          <Input placeholder="Enter store email" />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Platform"
          rules={genRules(
            [{ required: true, message: "Platform is required" }],
            !value,
          )}
        >
          <Select
            options={Object.values(PLATFORM).map((value) => ({
              value,
              label: value,
            }))}
          />
        </Form.Item>

        {!!value ? (
          <Form.Item name="status" label="Status">
            <Select
              options={Object.values(STORE_STATUS).map((value) => ({
                value,
                label: value,
              }))}
            />
          </Form.Item>
        ) : null}

        <Row>
          <Col>
            <Space size="middle">
              <Button children="Cancel" onClick={onCancel} />
              <Button
                children={btnLabel ? btnLabel : "Create"}
                type="primary"
                htmlType="submit"
                loading={loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

// Utils
const genRules = (rules, condition) => {
  return condition ? rules : null;
};
