import { gql } from "@apollo/client";

const CANCEL_TASK = gql`
  mutation cancelTask($id: ID!) {
    cancelTask(id: $id)
  }
`;

const ACCEPT_DESIGN_TASK = gql`
  mutation acceptDesignTask($id: ID!) {
    acceptDesignTask(id: $id)
  }
`;

const REJECT_DESIGN_TASK = gql`
  mutation rejectDesignTask($id: ID!, $reason: String) {
    rejectDesignTask(id: $id, reason: $reason)
  }
`;

const ASSIGN_TASK = gql`
  mutation assignTask($id: ID!, $designerId: ID!) {
    assignTask(id: $id, designerId: $designerId)
  }
`;

const START_TASK = gql`
  mutation startTask($id: ID!) {
    startTask(id: $id)
  }
`;

const UPLOAD_DESIGN_TASK = gql`
  mutation uploadDesignTask($input: NewUploadDesignTask!) {
    uploadDesignTask(input: $input) {
      id
      status
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($input: EditTask!) {
    updateTask(input: $input) {
      id
      status
    }
  }
`;

export {
  START_TASK,
  ASSIGN_TASK,
  CANCEL_TASK,
  UPDATE_TASK,
  ACCEPT_DESIGN_TASK,
  REJECT_DESIGN_TASK,
  UPLOAD_DESIGN_TASK,
};
