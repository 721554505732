import { Modal } from "antd";
import React from "react";
import Media from "../media";

function MediaModal(
  { open, onCancel, accept, multiple, value, onChange, onSetFile },
  _ref,
) {
  return (
    <Modal
      title="Media"
      width="70%"
      okText="Set File"
      open={open}
      onCancel={onCancel}
      bodyStyle={{ padding: 0, margin: 0 }}
      style={{ top: 30, padding: 0 }}
      className="media-modal"
      onOk={onSetFile}
    >
      <Media
        accept={accept}
        value={value}
        multiple={multiple}
        onChange={onChange}
      />
    </Modal>
  );
}

export default React.forwardRef(MediaModal);
