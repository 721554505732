import { Breadcrumb } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import LinkComp from "../../link";

export default function BreadcrumbComp() {
  const { pathname } = useLocation();

  const items = React.useMemo(() => {
    const splitted = (pathname || "").split("/");
    const paths = splitted.slice(1, splitted.length - 1);

    return [
      {
        title: "Home",
        href: "/",
        key: "home",
      },
      ...paths.map((path, index) => {
        let title = path;
        if (new RegExp("-").test(title)) {
          title = title
            .split("-")
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(" ");
        } else {
          title = path.charAt(0).toUpperCase() + path.slice(1);
        }

        return {
          key: `${path}_${index}`,
          title: <LinkComp children={title} href={"/" + path} />,
        };
      }),
    ];
  }, [pathname]);

  return <Breadcrumb items={items} />;
}
