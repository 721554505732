import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

export default function Spinner() {
  return (
    <Wrapper>
      <Spin size="large" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;
