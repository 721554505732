import { Space, Typography } from "antd";
import React from "react";
import { CreateBase } from "../../../components/base";
import BreadcrumbComp from "../../../components/shared/breadcrumb";

export default function CreateProductBase() {
  return (
    <React.Fragment>
      <Space direction="vertical" size="small">
        <BreadcrumbComp />
        <Typography.Title
          level={1}
          children="Create Product Base"
          style={{ margin: 0 }}
        />
      </Space>
      <CreateBase />
    </React.Fragment>
  );
}
