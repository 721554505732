import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { CREATE_PRODUCT_BASE } from "../../graphql/mutation";
import { BaseForm } from "./baseForm";

export function CreateBase() {
  const { notification } = useAppContext();
  const navigate = useNavigate();

  const [createBase, { loading }] = useMutation(CREATE_PRODUCT_BASE, {
    onCompleted: (res) => {
      if (res?.createProductBase) {
        notification.success({ message: "Create product base successfully." });
        navigate("/product-bases?from=create");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!values || typeof values !== "object") return;
      createBase({
        variables: {
          input: values,
        },
      });
    },
    [createBase],
  );

  return (
    <BaseForm name="Create base" loading={loading} onFinish={handleFinish} />
  );
}
