import { gql } from '@apollo/client';
import { StoreFrag } from '../fragment';

const STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        ...StoreFrag
      }
    }
  }
  ${StoreFrag}
`;

const STORE_BY_ID = gql`
  query storeById($id: ID!) {
    storeById(id: $id) {
      ...StoreFrag
    }
  }
  ${StoreFrag}
`;

export { STORES, STORE_BY_ID };