import { useAppContext } from "../../context";
import { CHANGE_PASSWORD } from "../../graphql/mutation";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Modal } from "antd";
import React from "react";
import styled from "styled-components";

export default function ChangePassword({ open, onCancel, isAdmin, userId }) {
  const { notification, currentUser, authenticate } = useAppContext();
  let newUserId = isAdmin ? currentUser?.id : userId;

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: async (res) => {
      if (res?.changePassword) {
        notification.info({ message: "Change password successfully" });
      }
      onCancel?.();
      isAdmin && authenticate(null);
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleSubmit = React.useCallback(
    (values) => {
      if (!newUserId) return;
      changePassword({
        variables: {
          userId: newUserId,
          ...values,
        },
      }).finally(() => {
        onCancel();
      });
    },
    [newUserId, changePassword, onCancel],
  );

  return (
    <Modal
      title="Change Password"
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Wrapper>
        <Form layout="vertical" autoComplete="off" onFinish={handleSubmit}>
          <Form.Item
            name="oldPass"
            label="Old Password"
            hasFeedback
            hidden={!isAdmin}
            rules={
              isAdmin
                ? [
                    {
                      required: true,
                      message: "Please input your Old password",
                    },
                  ]
                : null
            }
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPass"
            label="New Password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your New password",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className="actions">
            <Button children="Cancel" onClick={onCancel} />
            <Button
              children="Submit"
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </div>
        </Form>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  .actions {
    display: flex;
    column-gap: 12px;
    justify-content: end;
  }
`;
