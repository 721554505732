import { gql } from "@apollo/client";
import {
  ProductChartFrag,
  SaleChannelChartFrag,
  StoreChartFrag,
} from "../fragment";

const SALE_CHANNEL_CHART = gql`
  query saleChannelChart($filter: ChartFilter!) {
    saleChannelChart(filter: $filter) {
      ...SaleChannelChartFrag
    }
  }
  ${SaleChannelChartFrag}
`;

const STORE_CHART = gql`
  query storeChart($filter: ChartFilter!) {
    storeChart(filter: $filter) {
      ...StoreChartFrag
    }
  }
  ${StoreChartFrag}
`;

const PRODUCT_CHART = gql`
  query productChart($filter: ChartFilter!) {
    productChart(filter: $filter) {
      ...ProductChartFrag
    }
  }
  ${ProductChartFrag}
`;

export { SALE_CHANNEL_CHART, STORE_CHART, PRODUCT_CHART };
