import { gql } from '@apollo/client';

const UserFrag = gql`
  fragment UserFrag on User {
    id
    createdAt
    fullName
    email
    roles
    phone
    address
    status
    verified
  }
`;

export { UserFrag };