import { useQuery } from "@apollo/client";
import { Card, Row, Space, Typography } from "antd";
import React from "react";
import Suspense from "../../../components/shared/suspense";
import { TrackingProvider } from "../../../components/tracking/context";
import { GET_AMZ_ORDERS_NEED_ADD_TRACKING } from "../../../graphql/query";

const TrackingFilter = React.lazy(() =>
  import("../../../components/tracking/filter"),
);
const ExportTrackingBtn = React.lazy(() =>
  import("../../../components/tracking/exportTrackingBtn"),
);
const TrackingTable = React.lazy(() =>
  import("../../../components/tracking/dataTable"),
);

export default function ExportTracking() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      storeId: null,
      authorId: null,
    }),
    [],
  );

  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(
    GET_AMZ_ORDERS_NEED_ADD_TRACKING,
    {
      variables: {
        filter,
      },
    },
  );

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <TrackingProvider refetch={refetch}>
      <Row justify="space-between">
        <Typography.Title level={1} children="Export Tracking" />
        <ExportTrackingBtn
          filter={filter}
          disabled={
            (data?.getAmzOrdersNeedAddTracking?.nodes?.length || 0) === 0 ||
            !filter.storeId
          }
        />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Suspense>
          <TrackingFilter
            onChange={handleFilterChange}
            filter={filter}
            handleClearFilter={handleClearFilter}
          />
        </Suspense>
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <Suspense>
              <TrackingTable
                loading={loading}
                data={data?.getAmzOrdersNeedAddTracking?.nodes || []}
                total={data?.getAmzOrdersNeedAddTracking?.total || 0}
                handlePaginationChange={handlePaginationChange}
                filter={filter}
              />
            </Suspense>
          )}
        </Card>
      </Space>
    </TrackingProvider>
  );
}
