import { Modal } from "antd";

function PreviewFile({ open, onCancel, previewImage }) {
  return (
    <Modal open={open} footer={null} onCancel={onCancel}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          alt={""}
          style={{ width: "100%", padding: "15px 5px 5px 5px" }}
          src={previewImage}
        />
      </div>
    </Modal>
  );
}

export default PreviewFile;
