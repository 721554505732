import { Button, Drawer } from "antd";
import React from "react";
import { useToggle } from "../../hooks";

export default function MoreFilter({ children, handleClearFilter }) {
  const [open, toggleOpen] = useToggle(false);
  const handleClick = React.useCallback(() => {
    toggleOpen();
    handleClearFilter?.();
  }, [handleClearFilter, toggleOpen]);

  return (
    <>
      <Button children="More Filter" onClick={toggleOpen} />
      <Drawer
        title="More Filter"
        onClose={toggleOpen}
        open={open}
        footer={
          <Button
            children="Clear"
            onClick={handleClick}
            type="link"
            style={{ paddingLeft: 0 }}
          />
        }
      >
        {children}
      </Drawer>
    </>
  );
}
