import BreadcrumbComp from "../../../components/shared/breadcrumb";
import { Space, Typography } from "antd";
import React from "react";

const CreateOrder = React.lazy(() =>
  import("../../../components/order/createOrder"),
);

export default function CreateOrderPage() {
  return (
    <React.Fragment>
      <Space direction="vertical" size="small">
        <BreadcrumbComp />
        <Typography.Title
          level={1}
          children="Create Manual Order"
          style={{ margin: 0 }}
        />
      </Space>
      <CreateOrder />
    </React.Fragment>
  );
}
