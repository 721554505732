import { useQuery } from "@apollo/client";
import { Button, Card, Row, Space, Typography } from "antd";
import React from "react";
import { BaseContextProvider, BaseFilter } from "../../components/base";
import LinkComp from "../../components/link";
import Suspense from "../../components/shared/suspense";
import { PRODUCT_BASES } from "../../graphql/query";

const BaseTable = React.lazy(() => import("../../components/base/dataTable"));

export default function BasePage() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      fulfillmentId: null,
    }),
    [],
  );

  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(PRODUCT_BASES, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Product Bases" />
        <Button type="primary">
          <LinkComp
            href="/product-bases/create"
            children="Create Product Base"
          />
        </Button>
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <BaseFilter
          onChange={handleFilterChange}
          handleClearFilter={handleClearFilter}
        />
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <BaseContextProvider refetch={refetch}>
              <Suspense>
                <BaseTable
                  loading={loading}
                  filter={filter}
                  data={data?.productBases?.nodes || []}
                  total={data?.productBases?.total || 0}
                  handlePaginationChange={handlePaginationChange}
                />
              </Suspense>
            </BaseContextProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
