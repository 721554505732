import { createContext, useContext, useMemo } from "react";

const MappingOrderContext = createContext();

export function MappingOrderProvider({ children, ...props }) {
  const bag = useMemo(() => ({ ...props }), [props]);

  return (
    <MappingOrderContext.Provider value={bag}>
      {children}
    </MappingOrderContext.Provider>
  );
}

export function useMappingOrderCtx() {
  return useContext(MappingOrderContext);
}
