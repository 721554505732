import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import Library from "./library";
import UploadFile from "./uploadFile";

const Media = ({ value, multiple, accept, onChange }) => {
  const handleUploadFileChange = React.useCallback(
    (files) => {
      let newVal = [];
      if (files?.length > 0) {
        if (multiple) {
          for (let i = 0; i < files.length; i++) {
            if (!value.find((v) => v.id === files[i].id)) {
              newVal.push(files[i]);
            }
          }
          newVal = [...value, ...newVal];
        } else {
          newVal = files.length ? [files[0]] : [];
        }
      }

      onChange?.(newVal);
    },
    [multiple, onChange, value],
  );

  return (
    <Wrapper>
      <Tabs
        animated={false}
        defaultActiveKey="library"
        items={[
          {
            label: "Upload File",
            key: "upload",
            children: (
              <UploadFile
                onChange={handleUploadFileChange}
                accept={accept}
                multiple={multiple}
              />
            ),
          },
          {
            label: "Media Library",
            key: "library",
            children: (
              <Library value={value} onChange={onChange} multiple={multiple} />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0;
  }

  .inner {
    padding: 16px 0px 10px 20px;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .filter {
    margin-bottom: 20px;
    margin-top: 10px;
    @media (min-width: 768px) {
      display: flex;
    }
    .filter-left {
      flex-grow: 1;
      .ant-calendar-picker {
        width: 250px;
      }
    }
  }

  .files-container {
    display: flex;
    flex-wrap: wrap;

    .file {
      width: 150px;
      height: 150px;
      background: rgb(214, 224, 233);
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: rgb(137, 157, 173);
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;

      .check {
        position: absolute;
        right: 0;
        top: -2px;
        color: rgb(0, 114, 216);
      }
    }
  }

  .media-library-tab {
    .ant-tabs-top-bar {
      padding-left: 20px;
    }
  }

  .picture-outlined {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 20px;
    }
  }

  .ant {
    &-tabs {
      &-nav {
        margin: 0 !important;

        &-wrap {
          padding: 0 24px;
        }
      }

      &-content-holder {
        padding: 0 24px;
      }
    }
  }
`;

export default Media;
