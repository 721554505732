import { gql } from "@apollo/client";
import {
  MappingOrderFrag,
  OrderAggregationFrag,
  OrderDesignFrag,
  OrderFrag,
  OrderTimelineFrag,
  ProductFrag,
} from "../fragment";

const ORDERS = gql`
  query orders($filter: OrderFilter!) {
    orders(filter: $filter) {
      total
      totalOrders
      totalBaseCosts
      totalShippingCosts
      totalRevenues
      nodes {
        ...OrderFrag
      }
    }
  }
  ${OrderFrag}
`;

const ORDER_BY_ID = gql`
  query orderById($id: ID!) {
    orderById(id: $id) {
      ...OrderFrag
    }
  }
  ${OrderFrag}
`;

const ORDER_AGGREGATION = gql`
  query orderAggregation($filter: OrderFilter!) {
    orderAggregation(filter: $filter) {
      ...OrderAggregationFrag
    }
  }
  ${OrderAggregationFrag}
`;

const ORDER_TIMELINES = gql`
  query orderTimelines($id: ID!) {
    orderTimelines(id: $id) {
      ...OrderTimelineFrag
    }
  }
  ${OrderTimelineFrag}
`;

const GET_ORDER_DESIGNS = gql`
  query getOrderDesigns($id: ID!, $productBaseId: ID!) {
    getOrderDesigns(id: $id, productBaseId: $productBaseId) {
      ...OrderDesignFrag
    }
  }
  ${OrderDesignFrag}
`;

const MAPPING_ORDERS = gql`
  query mappingOrders($filter: MappingFilter) {
    mappingOrders(filter: $filter) {
      total
      nodes {
        ...MappingOrderFrag
      }
    }
  }
  ${MappingOrderFrag}
`;

const FIND_MAPPING_PRODUCT_SUGGEST = gql`
  query findMappingProductSuggest($mappingId: ID!) {
    findMappingProductSuggest(mappingId: $mappingId) {
      ...ProductFrag
    }
  }
  ${ProductFrag}
`;

export {
  ORDERS,
  ORDER_BY_ID,
  ORDER_AGGREGATION,
  ORDER_TIMELINES,
  GET_ORDER_DESIGNS,
  MAPPING_ORDERS,
  FIND_MAPPING_PRODUCT_SUGGEST,
};
