import { useMutation } from "@apollo/client";
import { Button, Card, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { REGISTER } from "../../graphql/mutation";
import UserForm from "../shared/user-form";

export function CreateUser() {
  const navigate = useNavigate();
  const { notification } = useAppContext();

  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted: (res) => {
      if (res?.register) {
        notification.success({ message: "Create member successfully." });
        navigate("/members?from=create");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });
  const handleFinish = React.useCallback(
    (values) => {
      register({
        variables: {
          input: values,
        },
      });
    },
    [register],
  );

  const handleCancel = React.useCallback(() => {
    navigate("/members");
  }, [navigate]);

  const actionsMarkup = React.useMemo(() => {
    return (
      <Space>
        <Button children="Cancel" onClick={handleCancel} />
        <Button
          children="Create"
          type="primary"
          htmlType="submit"
          loading={loading}
        />
      </Space>
    );
  }, [handleCancel, loading]);

  return (
    <Card style={{ marginTop: 16 }}>
      <UserForm
        isRegister
        onFinish={handleFinish}
        footer={actionsMarkup}
        textAlign="end"
      />
    </Card>
  );
}
