import { gql } from "@apollo/client";

const SaleChannelChartFrag = gql`
  fragment SaleChannelChartFrag on SaleChannelChart {
    totalItems
    totalOrders
    revenues
    totalBaseCost
    totalShippingCost
    etsy
    amazon
    chartValues {
      total
      date
      formatDate
    }
  }
`;

const StoreChartFrag = gql`
  fragment StoreChartFrag on StoreChart {
    totalItems
    totalOrders
    revenues
    totalBaseCost
    chartValues {
      storeId
      storeName
      totalItems
      totalOrders
      revenues
      totalBaseCost
      totalShippingCost
    }
  }
`;

const ProductChartFrag = gql`
  fragment ProductChartFrag on ProductChart {
    productId
    totalSales
    product {
      id
      title
      sku
      personalized
      images {
        file {
          id
          url
          thumbnail
        }
      }
    }
  }
`;

export { SaleChannelChartFrag, StoreChartFrag, ProductChartFrag };
