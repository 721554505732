import { ROLES } from "./index";
import Icons from "../components/icons";

const MENU = [
  {
    key: "members",
    label: "Members",
    roles: [ROLES.Admin],
    icon: <Icons.User width={20} height={20} />,
    children: [
      { key: "members_all", label: "All Members" },
      { key: "members/create", label: "Create Member" },
    ],
  },
  {
    key: "fulfillments",
    label: "Fulfillments",
    roles: [ROLES.Admin],
    icon: <Icons.Fulfillment width={24} height={24} />,
    children: [{ key: "fulfillments_all", label: "All Fulfillments" }],
  },
  {
    key: "product-bases",
    label: "Product Bases",
    roles: [ROLES.Admin],
    icon: <Icons.App />,
    children: [
      { key: "product-bases_all", label: "All Product Bases" },
      { key: "product-bases/create", label: "Create Product Base" },
    ],
  },
  {
    key: "products",
    label: "Products",
    icon: <Icons.Product />,
    children: [
      { key: "products_all", label: "All Products" },
      {
        key: "products/create",
        label: "Create Product",
        roles: [ROLES.Admin, ROLES.Seller],
      },
    ],
  },
  {
    key: "stores",
    label: "Stores",
    roles: [ROLES.Admin, ROLES.Seller],
    icon: <Icons.Store />,
    children: [{ key: "stores_all", label: "All Stores" }],
  },
  {
    key: "orders",
    label: "Orders",
    roles: [ROLES.Admin, ROLES.Seller],
    icon: <Icons.Order />,
    children: [
      { key: "orders_all", label: "All Orders" },
      { key: "orders/mapping", label: "Mapping Orders" },
      { key: "orders/create-manual", label: "Create Manual Order" },
      { key: "orders/export-tracking", label: "Export Tracking" },
      {
        key: "orders/export-tracking-history",
        label: "Export Tracking History",
      },
    ],
  },
  {
    key: "tasks",
    label: "Tasks",
    icon: <Icons.List width={22} height={22} />,
    children: [{ key: "tasks_all", label: "All Tasks" }],
  },
];

export default MENU;
