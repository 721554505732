import { Table, Tag } from "antd";
import React from "react";
import Suspense from "../shared/suspense";
const Actions = React.lazy(() => import("./actions"));

export function TableUser({
  data,
  loading,
  filter,
  total,
  handlePaginationChange,
}) {
  return (
    <Table
      dataSource={data}
      columns={columns}
      sticky
      loading={loading}
      rowKey={({ id }) => id}
      scroll={{ x: columns.reduce((acc, cur) => acc + (cur.width || 0), 0) }}
      pagination={{
        total,
        pageSize: filter?.limit || 20,
        current: filter.paged || 1,
        onChange: (paged, limit) => handlePaginationChange({ paged, limit }),
      }}
    />
  );
}

const BADGE_STATUS = {
  Active: "success",
  Inactive: "error",
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 200,
  },
  {
    title: "Fullname",
    dataIndex: "fullName",
    key: "full-name",
    width: 250,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 300,
  },
  {
    title: "Role",
    key: "role",
    width: 200,
    render: (_, { roles }) => {
      const role = (roles || []).join(", ");
      return <span>{role}</span>;
    },
  },
  {
    title: "status",
    key: "status",
    width: 150,
    render: (_, { status }) => {
      const statusMarkup = (
        <Tag color={BADGE_STATUS[status]}>
          <span>{status}</span>
        </Tag>
      );
      return statusMarkup;
    },
  },
  {
    title: "Phone",
    key: "phone",
    dataIndex: "phone",
    width: 200,
  },
  {
    title: "Address",
    key: "address",
    dataIndex: "address",
    width: 200,
  },
  {
    title: "Created At",
    key: "createdAt",
    width: 140,
    render: (_, { createdAt }) => {
      const [date] = createdAt.split("T");
      const [y, m, d] = date.split("-");

      return (
        <span>
          {d}/{m}/{y}
        </span>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    fixed: "right",
    width: 150,
    render: (_, record) => {
      return (
        <Suspense>
          <Actions record={record} />
        </Suspense>
      );
    },
  },
];
