import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { UPDATE_PRODUCT_BASE } from "../../graphql/mutation";
import { invalidOb } from "../../utils";
import { BaseForm } from "./baseForm";

export function UpdateBase({ value }) {
  const navigate = useNavigate();
  const { notification } = useAppContext();
  const { id } = value;

  const [updateBase, { loading }] = useMutation(UPDATE_PRODUCT_BASE, {
    onCompleted: (res) => {
      if (res?.updateProductBase) {
        notification.success({ message: "Update product base successfully." });
        navigate("/product-bases?from=update");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!id || invalidOb(values)) return;

      updateBase({
        variables: {
          input: {
            id,
            ...values,
          },
        },
      });
    },
    [id, updateBase],
  );

  return (
    <BaseForm
      name="Update base"
      loading={loading}
      onFinish={handleFinish}
      value={value}
    />
  );
}
