const { gql } = require("@apollo/client");

const TrackingFrag = gql`
  fragment TrackingFrag on Tracking {
    id
    code
    newCode
  }
`;

const AmzTrackingFrag = gql`
  fragment AmzTrackingFrag on AmazonTracking {
    id
    createdAt
    store {
      id
      title
    }
    author {
      id
      fullName
    }
  }
`;

const AmzOrdersNeedAddTrackingFrag = gql`
  fragment AmzOrdersNeedAddTrackingFrag on AmzOrdersNeedAddTracking {
    mess
    order {
      id
      originId
      store {
        id
        title
      }
    }
  }
`;

export { TrackingFrag, AmzTrackingFrag, AmzOrdersNeedAddTrackingFrag };
