import { useMutation } from "@apollo/client";
import { Button, Col, Form, Modal, Row, Space } from "antd";
import React from "react";
import { useAppContext } from "../../context";
import { CREATE_FULFILLMENT_CONFIGURE } from "../../graphql/mutation";
import { useFulfillmentContext } from "./context";

export default function InstallFulfillment({
  open,
  name,
  onCancel,
  formFields,
  fulfillmentId,
}) {
  const [form] = Form.useForm();

  const { notification } = useAppContext();
  const { refetch } = useFulfillmentContext();

  // Mutation
  const [install, { loading }] = useMutation(CREATE_FULFILLMENT_CONFIGURE, {
    onCompleted: (res) => {
      if (res?.createFulfillmentConfigure) {
        notification.success({
          message: "Install successfully.",
        });
        onCancel?.();
        refetch?.();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (fulfillmentId && values != null && typeof values === "object") {
        install({
          variables: {
            input: {
              ...values,
              fulfillmentId,
            },
          },
        });
      }
    },
    [install, fulfillmentId],
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okText="Install"
      footer={null}
      title={`Install ${name}`}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        style={{ marginTop: 10 }}
      >
        {formFields}
        <Row>
          <Col>
            <Space>
              <Button children="Cancel" onClick={onCancel} />
              <Button
                children="Submit"
                type="primary"
                htmlType="submit"
                loading={loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
