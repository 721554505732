import { useMutation } from "@apollo/client";
import { Avatar, Dropdown, Layout, theme } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { LOGOUT } from "../../graphql/mutation";
import { getTokenObject } from "../../utils";
import ChangePassword from "../change-password";
import Icons from "../icons";
import Profile from "../profile";

const { Header } = Layout;
const { useToken } = theme;

const headerStyle = {
  position: "sticky",
  top: 0,
  zIndex: 100,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingInline: "20px",
  backgroundColor: "rgba(241, 242, 244, 1)",
  boxShadow: `0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1),
  0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05)`,
};

export default function HeaderComp() {
  const { token } = useToken();
  const navigate = useNavigate();
  const { currentUser, loading, notification, authenticate } = useAppContext();

  const [open, setOpen] = React.useReducer((p, s) => ({ ...p, ...s }), {
    profile: false,
    changePass: false,
  });

  // Mutation
  const [logout] = useMutation(LOGOUT, {
    onCompleted: (res) => {
      notification.info({ message: "Logout successfully." });
      if (res?.logout) {
        authenticate(null);
        navigate("/login");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  // Actions
  const toggleOpen = React.useCallback(
    (key) => {
      return () => {
        const cur = open[key];
        setOpen({ [key]: !cur });
      };
    },
    [open],
  );

  const handleLogout = React.useCallback(() => {
    const token = getTokenObject();
    if (!token) return;

    logout({
      variables: {
        token: token.id,
      },
    });
  }, [logout]);

  return loading || !currentUser ? null : (
    <Header style={headerStyle}>
      <div className="logo">
        <a href="/" style={{ color: "rgba(31, 33, 36, 0.88)" }}>
          <h2>QDEcom</h2>
        </a>
      </div>
      <div className="menu">
        <Dropdown
          placement="bottom"
          menu={{
            items: [
              {
                label: "Profile",
                key: "profile",
                onClick: toggleOpen("profile"),
              },
              {
                label: "Change Password",
                key: "changePassword",
                onClick: toggleOpen("changePass"),
              },
              { label: "Logout", key: "logout", onClick: handleLogout },
            ],
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 5,
              cursor: "pointer",
            }}
          >
            <Avatar
              style={{
                backgroundColor: token.colorSuccess,
                cursor: "pointer",
              }}
              icon={
                <span
                  role="img"
                  aria-label="user"
                  className="anticon anticon-user"
                >
                  <Icons.User height={18} width={18} />
                </span>
              }
            />
            <span>{currentUser.fullName}</span>
          </div>
        </Dropdown>
      </div>
      <ChangePassword
        open={open.changePass}
        onCancel={toggleOpen("changePass")}
        isAdmin
      />
      <Profile open={open.profile} onCancel={toggleOpen("profile")} />
    </Header>
  );
}
