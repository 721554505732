import { useQuery } from "@apollo/client";
import { Skeleton, Space, Typography } from "antd";
import React from "react";
import { UpdateProduct } from "../../../components/product/updateProduct";
import BreadcrumbComp from "../../../components/shared/breadcrumb";
import { PRODUCT_BY_ID } from "../../../graphql/query";
import { useParams } from "react-router-dom";

export default function CreateProduct() {
  const { id } = useParams();

  const { data, loading, error } = useQuery(PRODUCT_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
  });

  return (
    <React.Fragment>
      <Space direction="vertical" size="small">
        <BreadcrumbComp />
        <Typography.Title
          level={1}
          children="Update Product"
          style={{ margin: 0 }}
        />
      </Space>
      {loading ? (
        <Skeleton />
      ) : error ? (
        <div>Error: {error?.toString()}</div>
      ) : (
        <UpdateProduct value={data?.productByID} />
      )}
    </React.Fragment>
  );
}
