import { Radio, Space } from "antd";
import React from "react";
import { useDebounce } from "../../hooks";

export default function RadioFilter({ options = [], value, onChange, label }) {
  const [val, setValue] = React.useState(value || "all");

  React.useEffect(() => {
    setValue((p) => {
      let val = value ?? "all";
      if (p !== val) return val;
      return p;
    });
  }, [value]);

  const handleChange = React.useCallback(({ target }) => {
    const { value } = target;
    setValue(value);
  }, []);

  useDebounce(
    () => {
      onChange?.(val);
    },
    500,
    [val],
  );

  return (
    <Space direction="vertical">
      <label>{label}</label>
      <Radio.Group onChange={handleChange} value={val}>
        <Space direction="vertical">
          <Radio value={"all"} children="All" />
          {options.map(({ value, label: children }) => (
            <Radio value={value} key={value} children={children} />
          ))}
        </Space>
      </Radio.Group>
    </Space>
  );
}
