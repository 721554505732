import { gql } from '@apollo/client';
import { FileFrag } from './file';

const BaseFulfillmentFrag = gql`
  fragment BaseFulfillmentFrag on ProductBaseFulfillment {
    fulfillmentId
    productId
    presetId
  }
`;

const BaseAttributeFrag = gql`
  fragment BaseAttributeFrag on ProductBaseAttribute {
    name
    slug
    option
  }
`;

const BaseVariantAttributeFrag = gql`
  fragment BaseVariantAttributeFrag on ProductBaseVariantAttribute {
    name
    slug
    option
  }
`;

const BaseVariantFrag = gql`
  fragment BaseVariantFrag on ProductBaseVariant {
    id
    productBaseId
    regularPrice
    salePrice
    baseCost
    sorting
    fulfillmentProductId
    attributes {
      ...BaseVariantAttributeFrag
    }
  }
  ${BaseVariantAttributeFrag}
`;

const BaseDesignPositionFrag = gql`
  fragment BaseDesignPositionFrag on ProductBaseDesignPosition {
    id
    name
    description
    productBaseId
    sorting
    productBaseVariants {
      ...BaseVariantFrag
    }
  }
  ${BaseVariantFrag}
`;

const BaseFrag = gql`
  fragment BaseFrag on ProductBase {
    id
    createdAt
    title
    regularPrice
    salePrice
    baseCost
    fulfillment {
      ...BaseFulfillmentFrag
    }
    attributes {
      ...BaseAttributeFrag
    }
    variants {
      ...BaseVariantFrag
    }
    designPositions {
      ...BaseDesignPositionFrag
    }
    imageId
    image {
      ...FileFrag
    }
  }
  ${BaseFulfillmentFrag}
  ${BaseAttributeFrag}
  ${BaseVariantFrag}
  ${BaseDesignPositionFrag}
  ${FileFrag}
`;

export { BaseFrag, BaseVariantFrag, BaseDesignPositionFrag }