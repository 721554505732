import { useQuery } from "@apollo/client";
import { Skeleton, Space, Typography } from "antd";
import { FulfillmentContextProvider } from "../../components/fulfillment/context";
import { FulfillmentSection } from "../../components/fulfillment/section";
import { FULFILLMENT } from "../../constants";
import { FULFILLMENTS } from "../../graphql/query";

export default function Fulfillments() {
  const { data, loading, error, refetch } = useQuery(FULFILLMENTS, {
    fetchPolicy: "no-cache",
  });

  const fulfillments = (data?.fulfillments || []).filter(
    ({ slug }) => slug !== FULFILLMENT.Custom.slug,
  );
  return (
    <>
      <Typography.Title level={1} children={"All Fulfillments"} />
      {loading ? (
        <Skeleton />
      ) : error ? (
        <div>Error: {error?.toString()}</div>
      ) : fulfillments.length > 0 ? (
        <FulfillmentContextProvider refetch={refetch}>
          <Space direction="vertical" style={{ display: "flex" }} size="large">
            {fulfillments.map((fulfillment) => {
              return (
                <FulfillmentSection
                  key={fulfillment?.id || Math.random()}
                  fulfillment={fulfillment}
                />
              );
            })}
          </Space>
        </FulfillmentContextProvider>
      ) : null}
    </>
  );
}
