import { Card } from "antd";
import React from "react";
import styled from "styled-components";

const FormWrap = ({ children }) => {
  return (
    <Wrapper>
      <Card bordered={false} bodyStyle={{ padding: "30px 32px", minWidth: 350 }}>
        {children}
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;

  .login-form-forgot {
    float: right;
  }

  .ant-col-rtl .login-form-forgot {
    float: left;
  }

  .ant-btn-primary {
    width: 100%;
  }
`;

export default FormWrap;
