import { Input } from "antd";
import React from "react";
import styled from "styled-components";
import { useDebounce } from "../../hooks";
import Icons from "../icons";

export default function Filter({
  filter = {},
  moreFilter,
  placeholder,
  onChange,
}) {
  const [search, setSearch] = React.useState(filter.search || "");

  const handleSearch = React.useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  useDebounce(
    () => {
      onChange?.(search);
    },
    500,
    [search],
  );

  return (
    <Wrapper>
      <Input
        prefix={<Icons.Search width={16} height={16} />}
        placeholder={placeholder ?? "Search .."}
        onChange={handleSearch}
        value={search}
      />
      {moreFilter}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;
