import React from "react";

export const useTimeoutFn = (fn, ms) => {
  const ready = React.useRef(false);
  const timeout = React.useRef();
  const callback = React.useRef(fn);

  const isReady = React.useCallback(() => ready.current, []);

  const set = React.useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);

  const clear = React.useCallback(() => {
    ready.current = null;
    timeout.current && clearTimeout(timeout.current);
  }, []);

  // Update ref when function change
  React.useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // Set on mount, clear on unmount
  React.useEffect(() => {
    set();

    return clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms]);

  return [isReady, clear, set];
};

export const useDebounce = (fn, ms = 0, deps = []) => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(reset, deps);

  return [isReady, cancel];
};

export const useToggle = (initVal) => {
  return React.useReducer(
    (state, nextVal) => (typeof nextVal === "boolean" ? nextVal : !state),
    initVal,
  );
};
