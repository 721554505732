import { Space, Typography } from "antd";
import React from "react";
import BreadcrumbComp from "../../../components/shared/breadcrumb";
import { CreateUser } from "../../../components/user";

export default function AddUserPage() {
  return (
    <React.Fragment>
      <Space direction="vertical" size="small">
        <BreadcrumbComp />
        <Typography.Title
          level={1}
          children="Create Member"
          style={{ margin: 0 }}
        />
      </Space>
      <CreateUser />
    </React.Fragment>
  );
}
