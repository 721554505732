import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { UPDATE_PRODUCT } from "../../graphql/mutation";
import { invalidOb } from "../../utils";
import ProductForm from "./productForm";

export function UpdateProduct({ value }) {
  const { id } = value || {};
  const { notification } = useAppContext();
  const navigate = useNavigate();

  const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: (res) => {
      if (res?.updateProduct) {
        notification.success({ message: "Update product successfully." });
        navigate("/products?from=update");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!id || invalidOb(values)) return;

      updateProduct({
        variables: {
          input: {
            id,
            ...values,
          },
        },
      });
    },
    [updateProduct, id],
  );

  return (
    <ProductForm
      name="Update product"
      loading={loading}
      onFinish={handleFinish}
      value={value}
    />
  );
}
