import { useQuery } from "@apollo/client";
import {
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Statistic,
  Table,
  Tag,
  Typography,
} from "antd";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import get from "lodash.get";
import React from "react";
import { Doughnut, Line } from "react-chartjs-2";
import CountUp from "react-countup";
import styled from "styled-components";
import TimeFilter from "../../components/filter/time";
import Image from "../../components/image";
import { useAppContext } from "../../context";
import {
  PRODUCT_CHART,
  SALE_CHANNEL_CHART,
  STORE_CHART,
} from "../../graphql/query";
import { checkRole, getSrc } from "../../utils";

const formatter = (value, prefix = "", dec = 0) => (
  <CountUp
    end={value}
    separator="."
    decimals={dec}
    decimal=","
    redraw={true}
    prefix={prefix}
  />
);

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

export const data = {
  labels: ["Amazon", "Etsy"],
  datasets: [
    {
      label: "Top items",
      key: "compare",
      data: [663, 0],
      backgroundColor: ["rgba(255, 155, 1, 0.8)", "rgba(245, 87, 1, 0.8)"],
      borderColor: ["rgba(255, 155, 1, 1)", "rgba(245, 87, 1, 1)"],
      borderWidth: 1,
    },
  ],
};

const DATASETS = [
  {
    label: "",
    data: [],
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 1)",
  },
];

const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const HomePage = () => {
  const [values, setValues] = React.useState(data);
  const [dataResources, setDataResources] = React.useState({
    labels: [],
    datasets: DATASETS,
  });

  const { currentUser } = useAppContext();
  const { isDesigner } = checkRole(currentUser);

  const [filter, setFilter] = React.useState({
    time: {
      from: dayjs().startOf("d"),
      to: dayjs().endOf("d"),
    },
  });

  const {
    data: saleChannelData,
    loading,
    error,
  } = useQuery(SALE_CHANNEL_CHART, {
    variables: {
      filter,
    },
    skip: isDesigner,
  });

  React.useEffect(() => {
    if (saleChannelData?.saleChannelChart) {
      const { amazon, etsy, chartValues } = saleChannelData.saleChannelChart;
      setValues((p) => {
        const newValues = { ...p };
        return {
          ...newValues,
          datasets: [
            {
              ...newValues.datasets[0],
              data: [amazon, etsy],
            },
          ],
        };
      });

      const labels = [""];
      const data = [0];
      if (chartValues?.length > 0) {
        for (let item of chartValues) {
          labels.push(item.formatDate);
          data.push(item.total);
        }
      }

      setDataResources((p) => {
        return {
          ...p,
          labels,
          datasets: [
            {
              ...p.datasets,
              data,
            },
          ],
        };
      });
    }
  }, [saleChannelData]);

  const handleFilterChange = React.useCallback((range) => {
    setFilter((p) => ({ ...p, time: range }));
  }, []);

  if (isDesigner) {
    return (
      <div>
        <Typography.Title level={1} children="Dashboard" />
      </div>
    );
  }

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="Dashboard" />
        <TimeFilter
          onChange={handleFilterChange}
          label=""
          width={250}
          defaultValue={[dayjs().startOf("d"), dayjs().endOf("d")]}
        />
      </Row>
      {loading ? (
        <Skeleton />
      ) : error ? (
        <div>Error: {error.toString()}</div>
      ) : (
        <Wrapper>
          <Row gutter={24}>
            <Col span={5}>
              <Card>
                <Statistic
                  title="Revenue"
                  value={saleChannelData.saleChannelChart.revenues}
                  formatter={(value) => formatter(value, "$ ", 2)}
                />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic
                  title="Total Cost"
                  value={saleChannelData.saleChannelChart.totalBaseCost}
                  formatter={(value) => formatter(value, "$ ", 2)}
                />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic
                  title="Total Shipping Cost"
                  value={saleChannelData.saleChannelChart.totalShippingCost}
                  formatter={(value) => formatter(value, "$ ", 2)}
                />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic
                  title="Total Items"
                  value={saleChannelData.saleChannelChart.totalItems}
                  formatter={formatter}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Total Orders"
                  value={saleChannelData.saleChannelChart.totalOrders}
                  formatter={formatter}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: 24 }}>
            <Col span={9}>
              <Card>
                <Doughnut data={values} options={options} />
                <Line options={options2} data={dataResources} />
              </Card>
            </Col>
            <Col span={15}>
              <StoreComp filter={filter} />
              <ProductComp filter={filter} />
            </Col>
          </Row>
        </Wrapper>
      )}
    </>
  );
};

const StoreComp = ({ filter }) => {
  const { data: storeData } = useQuery(STORE_CHART, {
    variables: { filter },
  });

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "storeName",
      width: 150,
    },
    {
      title: "Sales",
      key: "sales",
      dataIndex: "totalOrders",
      width: 100,
    },
    {
      title: "Total Cost",
      key: "totalCost",
      dataIndex: "totalBaseCost",
      width: 150,
      render: (val) => {
        return <span>${val}</span>;
      },
    },
    {
      title: "Total Shipping Cost",
      key: "totalShippingCost",
      dataIndex: "totalShippingCost",
      width: 170,
      render: (val) => {
        return <span>${val}</span>;
      },
    },
    {
      title: "Total Revenue",
      key: "revenue",
      dataIndex: "revenues",
      width: 150,
      render: (val) => {
        return <span>${val}</span>;
      },
    },
  ];

  return (
    <Card title="Stores" bodyStyle={{ padding: 0, paddingTop: 1 }}>
      <Table
        dataSource={storeData?.storeChart?.chartValues || []}
        columns={columns}
        rowKey={(item) => item.storeId}
        scroll={{ x: columns.reduce((acc, cur) => acc + (cur.width || 0), 0) }}
      />
    </Card>
  );
};

const ProductComp = ({ filter }) => {
  const { data: productData } = useQuery(PRODUCT_CHART, {
    variables: { filter },
  });

  const columns = [
    {
      title: "Image",
      key: "image",
      dataIndex: "product",
      render: (product) => {
        const file = get(product, "images[0].file");
        const src = getSrc(file);
        return <Image width={120} height={120} src={src} />;
      },
    },
    {
      title: "ID",
      key: "id",
      dataIndex: "product",
      render: (product) => {
        return <span>{product?.id || ""}</span>;
      },
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "product",
      render: (product) => {
        const { title, sku, personalized } = product;
        return (
          <>
            <Space direction="vertical" size="small">
              <span>{title}</span>
              {personalized && <Tag color="success">Personalized</Tag>}
              <Tag>{sku}</Tag>
            </Space>
          </>
        );
      },
    },
    {
      title: "Total Orders",
      key: "total-orders",
      dataIndex: "totalSales",
      width: 120,
    },
  ];

  return (
    <Card
      title="Products"
      style={{ marginTop: 24 }}
      bodyStyle={{ padding: 0, paddingTop: 1 }}
    >
      <Table
        columns={columns}
        dataSource={productData?.productChart || []}
        rowKey={(item) => item.productId}
        pagination={{ style: { paddingRight: 20 } }}
      />
    </Card>
  );
};

const Wrapper = styled.div`
  .ant-statistic-title {
    font-size: 20px;
    font-weight: 700;
    color: rgba(31, 33, 36, 0.88);
  }
`;

export default HomePage;
