import React from "react";

export default function FormatTime({ data, separate, showH = true}) {
  if (!data || typeof data !== "string") return null;

  const [date, hour] = data.split("T");
  const [y, m, d] = date.split(separate ?? "-");

  return (
    <span>
      {d}/{m}/{y} {showH ? hour.slice(0, 5) : ""}
    </span>
  );
}
