import { gql } from "@apollo/client";
import {
  TaskAggregationFrag,
  TaskFrag,
  TaskSubmissionFrag,
  TaskTimelineFrag,
} from "../fragment";

const TASKS = gql`
  query tasks($filter: TaskFilter!) {
    tasks(filter: $filter) {
      total
      nodes {
        ...TaskFrag
      }
    }
  }
  ${TaskFrag}
`;

const TASK_BY_ID = gql`
  query taskById($id: ID!) {
    taskById(id: $id) {
      ...TaskFrag
    }
  }
  ${TaskFrag}
`;

const TASK_AGGREGATION = gql`
  query taskAggregation($filter: TaskFilter!) {
    taskAggregation(filter: $filter) {
      ...TaskAggregationFrag
    }
  }
  ${TaskAggregationFrag}
`;

const GET_TASK_SUBMISSION = gql`
  query getTaskSubmission($taskId: ID!) {
    getTaskSubmission(taskId: $taskId) {
      ...TaskSubmissionFrag
    }
  }
  ${TaskSubmissionFrag}
`;

const GET_TASK_TIMELINES = gql`
  query getTaskTimelines($id: ID!) {
    getTaskTimelines(id: $id) {
      ...TaskTimelineFrag
    }
  }
  ${TaskTimelineFrag}
`;

export {
  TASKS,
  TASK_BY_ID,
  TASK_AGGREGATION,
  GET_TASK_SUBMISSION,
  GET_TASK_TIMELINES,
};
