import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Col,
  // Divider,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { LOGIN } from "../../graphql/mutation";
import Icons from "../icons";
import FormWrap from "../shared/form-wrap";

const LoginComp = () => {
  const navigate = useNavigate();
  const { authenticate, notification, currentUser } = useAppContext();

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      notification.success({ message: "Login successfully." });
      if (res?.login) {
        authenticate(res.login);
        navigate("/");
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  React.useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const styleIcon = React.useMemo(() => ({ height: 18, width: 18 }), []);
  const onFinish = React.useCallback(
    (values) => {
      login({
        variables: values,
      });
    },
    [login],
  );

  return (
    <FormWrap>
      <Form
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          remember: true,
        }}
      >
        <Typography.Title level={2} children="Welcome to QDEcom" />
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input your Email",
            },
          ]}
        >
          <Input prefix={<Icons.User {...styleIcon} />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your Password",
            },
          ]}
        >
          <Input
            prefix={<Icons.Lock {...styleIcon} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              Login
            </Button>
            {/* <Divider />
            <span>
              Or <a href="/register">register now!</a>
            </span> */}
          </Col>
        </Row>
      </Form>
    </FormWrap>
  );
};

export default LoginComp;
