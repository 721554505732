import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { API_DOMAIN } from "./config/config";
import "./index.css";
import { getTokenObject } from "./utils";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (new RegExp("ACCESS_DENIED", "gi").test(message)) {
        // window.location.href = "/login";
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  let token = "";
  const tokenObject = getTokenObject();
  if (tokenObject) {
    token = tokenObject.id;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = API_DOMAIN + "/query";

export const apolloClient = new ApolloClient({
  uri: link,
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, new HttpLink({ uri: link })]),
});

window.__psn_client__ = apolloClient;
