import { useQuery } from "@apollo/client";
import { Button, Card, Row, Space, Typography } from "antd";
import React from "react";
import LinkComp from "../../components/link";
import { OrderContextProvider } from "../../components/order/context";
import Suspense from "../../components/shared/suspense";
import { ORDERS } from "../../graphql/query";

const OrderFilter = React.lazy(() => import("../../components/order/filter"));
const OrderTable = React.lazy(() => import("../../components/order/dataTable"));
const OrderTab = React.lazy(() => import("../../components/order/tab"));

export default function OrderPage() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      status: null,
      productBaseId: null,
      storeId: null,
      fulfillmentId: null,
      platform: null,
      personalized: null,
      isManualOrder: null,
      createTime: null,
      isResentOrder: null,
      isFakeTracking: null,
    }),
    [],
  );
  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );
  const [refetchAgg, setRefetchAgg] = React.useState(() => {});

  const { data, loading, error, refetch } = useQuery(ORDERS, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  const handleStatusChange = React.useCallback((status) => {
    setFilter({ status });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);
  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  const { nodes, total, totalOrders, totalBaseCosts, totalShippingCosts, totalRevenues } =
    React.useMemo(() => {
      const { nodes, total, totalOrders, totalBaseCosts, totalShippingCosts, totalRevenues } =
        data?.orders || {};
      return {
        nodes,
        total,
        totalBaseCosts,
        totalShippingCosts,
        totalRevenues,
        totalOrders,
      };
    }, [data]);

  const handleRefetch = React.useMemo(() => {
    return () => {
      refetch();
      refetchAgg();
    };
  }, [refetch, refetchAgg]);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Orders" />
        <Button
          type="primary"
          children={
            <LinkComp
              children="Create Manual Order"
              href="/orders/create-manual"
            />
          }
        />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Suspense>
          <OrderFilter
            onChange={handleFilterChange}
            filter={filter}
            handleClearFilter={handleClearFilter}
          />
        </Suspense>
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <OrderContextProvider refetch={handleRefetch}>
              <Suspense>
                <OrderTab
                  onChange={handleStatusChange}
                  filter={filter}
                  setRefetchAgg={setRefetchAgg}
                />
                <OrderTable
                  loading={loading}
                  data={nodes || []}
                  total={total}
                  totalBaseCosts={totalBaseCosts}
                  totalShippingCosts={totalShippingCosts}
                  totalRevenues={totalRevenues}
                  totalOrders={totalOrders}
                  handlePaginationChange={handlePaginationChange}
                  filter={filter}
                />
              </Suspense>
            </OrderContextProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
