import React from "react";
const StoreContext = React.createContext(null);

const StoreContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <StoreContext.Provider value={bag}>{children}</StoreContext.Provider>;
};

const useStoreContext = () => {
  return React.useContext(StoreContext);
};

export { StoreContextProvider, useStoreContext };
