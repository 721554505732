import Filter from "../filter";
import RadioFilter from "../filter/radio";
import MoreFilter from "../more-filter";
import { FULFILLMENTS } from "../../graphql/query";
import { useQuery } from "@apollo/client";
import { Card, Space } from "antd";
import React from "react";

export function BaseFilter({ onChange, handleClearFilter }) {
  const [fulfillments, setFulfillments] = React.useState([]);

  const { data } = useQuery(FULFILLMENTS);

  React.useEffect(() => {
    const nodes = data?.fulfillments || [];
    const ffs = nodes.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    setFulfillments(ffs);
  }, [data]);
  const handleFilterChange = React.useCallback(
    (key) => (value) => {
      let newVal = [null, "all"].includes(value) ? null : value;
      onChange({ [key]: newVal });
    },
    [onChange],
  );

  const moreFilterMarkup = React.useMemo(() => {
    const children = (
      <Space direction="vertical" size="large">
        <RadioFilter
          label="Fulfillment"
          onChange={handleFilterChange("fulfillmentId")}
          options={fulfillments}
        />
      </Space>
    );

    return (
      <MoreFilter children={children} handleClearFilter={handleClearFilter} />
    );
  }, [handleFilterChange, fulfillments, handleClearFilter]);

  return (
    <Card>
      <Filter
        onChange={handleFilterChange("search")}
        moreFilter={moreFilterMarkup}
      />
    </Card>
  );
}
