import React from "react";
const ProductContext = React.createContext(null);

const ProductContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return (
    <ProductContext.Provider value={bag}>{children}</ProductContext.Provider>
  );
};

const useProductContext = () => {
  return React.useContext(ProductContext);
};

export { ProductContextProvider, useProductContext };
