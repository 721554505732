import {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const RouteChangeContext = createContext({});

export const useRouteChangeContext = () => useContext(RouteChangeContext);

function RouteChangeComplete() {
  const { onRouteChangeComplete } = useRouteChangeContext();

  const { pathname, search } = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onRouteChangeComplete(), [pathname, search]);

  return null;
}

export const RouteChangeProvider = ({ children }) => {
  const [routeChangeStartCallbacks] = useState([]);
  const [routeChangeCompleteCallbacks] = useState([]);

  const onRouteChangeStart = useCallback(() => {
    routeChangeStartCallbacks.forEach((callback) => callback());
  }, [routeChangeStartCallbacks]);

  const onRouteChangeComplete = useCallback(() => {
    routeChangeCompleteCallbacks.forEach((callback) => callback());
  }, [routeChangeCompleteCallbacks]);

  return (
    <RouteChangeContext.Provider
      value={{
        routeChangeStartCallbacks,
        routeChangeCompleteCallbacks,
        onRouteChangeStart,
        onRouteChangeComplete,
      }}
    >
      {children}
      <Suspense>
        <RouteChangeComplete />
      </Suspense>
    </RouteChangeContext.Provider>
  );
};

export const useRouteChange = (options) => {
  const { routeChangeStartCallbacks, routeChangeCompleteCallbacks } =
    useRouteChangeContext();

  useEffect(() => {
    // add callback to the list of callbacks and persist it
    if (options.onRouteChangeStart) {
      routeChangeStartCallbacks.push(options.onRouteChangeStart);
    }

    if (options.onRouteChangeComplete) {
      routeChangeCompleteCallbacks.push(options.onRouteChangeComplete);
    }

    return () => {
      // Find the callback in the array and remove it.
      if (options.onRouteChangeStart) {
        const index = routeChangeStartCallbacks.indexOf(
          options.onRouteChangeStart,
        );
        if (index > -1) {
          routeChangeStartCallbacks.splice(index, 1);
        }
      }

      if (options.onRouteChangeComplete) {
        const index = routeChangeCompleteCallbacks.indexOf(
          options.onRouteChangeComplete,
        );
        if (index > -1) {
          routeChangeCompleteCallbacks.splice(index, 1);
        }
      }
    };
  }, [options, routeChangeStartCallbacks, routeChangeCompleteCallbacks]);
};
