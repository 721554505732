import { gql } from "@apollo/client";
import {
  AmzOrdersNeedAddTrackingFrag,
  AmzTrackingFrag,
  TrackingFrag,
} from "../fragment";

const TRACKINGS_BY_ORDER_ID = gql`
  query trackingsByOrderId($orderId: ID!) {
    trackingsByOrderId(orderId: $orderId) {
      ...TrackingFrag
    }
  }
  ${TrackingFrag}
`;

const EXPORT_AMAZON_TRACKING_HISTORY = gql`
  query exportAmazonTrackingHistory($filter: AmazonTrackingFilter!) {
    exportAmazonTrackingHistory(filter: $filter) {
      total
      nodes {
        ...AmzTrackingFrag
      }
    }
  }
  ${AmzTrackingFrag}
`;

const GET_AMZ_ORDERS_NEED_ADD_TRACKING = gql`
  query getAmzOrdersNeedAddTracking($filter: AmazonTrackingFilter!) {
    getAmzOrdersNeedAddTracking(filter: $filter) {
      total
      nodes {
        ...AmzOrdersNeedAddTrackingFrag
      }
    }
  }
  ${AmzOrdersNeedAddTrackingFrag}
`;

export {
  TRACKINGS_BY_ORDER_ID,
  EXPORT_AMAZON_TRACKING_HISTORY,
  GET_AMZ_ORDERS_NEED_ADD_TRACKING,
};
