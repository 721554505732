import SecureLS from 'secure-ls';

let ls;
const init = () => {
  ls = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'personal',
    isCompression: false
  });
};

init()

export default ls;