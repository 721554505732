import React from "react";
import styles from "./styles.module.css";

const ProgressBar = () => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    let interval = setInterval(() => {
      setProgress((p) => (p >= 100 ? p : p + 10));
    }, 600);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingBar} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
