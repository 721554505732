import { gql } from '@apollo/client';
import { BaseFrag } from '../fragment';

const CREATE_PRODUCT_BASE = gql`
  mutation createProductBase($input: NewProductBase!) {
    createProductBase(input: $input) {
      ...BaseFrag
    }
  }
  ${BaseFrag}
`;

const UPDATE_PRODUCT_BASE = gql`
  mutation updateProductBase($input: EditProductBase!) {
    updateProductBase(input: $input) {
      ...BaseFrag
    }
  }
  ${BaseFrag}
`;

export { CREATE_PRODUCT_BASE, UPDATE_PRODUCT_BASE };