import { Layout, Menu } from "antd";
import get from "lodash.get";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MENU from "../../constants/menu";
import { useAppContext } from "../../context";
import { invalidOb, validRoles } from "../../utils";
import LinkComp from "../link";

const { Sider } = Layout;

const rootSubmenuKeys = MENU.map(({ key }) => key);

export default function SideBarComp() {
  const { currentUser, loading } = useAppContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userRoles = get(currentUser, "roles");

  const [openKeys, setOpenKeys] = React.useState([parentPath(pathname)]);
  const [currentKey, setCurrentKey] = React.useState([pathname.substring(1)]); // pathname = `/path` , expect = `path` => substring(1)

  const MENU_OPTIONS = React.useMemo(() => {
    return MENU.map((item) => {
      const childs = (item.children || [])
        .map((subMenu) => {
          let newKey = subMenu.key;
          if (new RegExp(/_/).test(newKey)) {
            [newKey] = newKey.split(/_/);
          }

          if (!invalidOb(subMenu.roles)) {
            if (!validRoles(userRoles, subMenu.roles)) return null;
          }
          return {
            ...subMenu,
            label: <LinkComp href={"/" + newKey} children={subMenu.label} />,
          };
        })
        .filter(Boolean);

      if (!invalidOb(item.roles)) {
        if (!validRoles(userRoles, item.roles)) return null;
      }

      return {
        ...item,
        children: childs,
        icon: <span>{item.icon}</span>,
        label: <LinkComp href={"/" + item.key} children={item.label} />,
      };
    }).filter(Boolean);
  }, [userRoles]);

  const handleRedirect = React.useCallback(
    (path) => {
      let curPath = Array.isArray(path) ? path[path.length - 1] : path;
      if (!curPath || pathname === curPath) return;
      navigate("/" + curPath);
    },
    [pathname, navigate],
  );

  const handleOpenKeyChange = React.useCallback(
    (keys) => {
      // Only open one menu
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      let newKeys;
      if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        newKeys = keys;
      } else {
        newKeys = latestOpenKey ? [latestOpenKey] : [];
      }

      setCurrentKey(getCurrentKey(newKeys));
      setOpenKeys(newKeys);
      handleRedirect(keys);
    },
    [handleRedirect, openKeys],
  );

  const handleClick = React.useCallback(
    ({ key, keyPath }) => {
      setCurrentKey(getCurrentKey(keyPath, key));

      const parent = keyPath[keyPath.length - 1];
      const split = (key || "").split("_");
      const [curPath] = split;
      if (parent === curPath && pathname === curPath) return;

      handleRedirect(curPath);
    },
    [handleRedirect, pathname],
  );

  React.useEffect(() => {
    const newKeys = [pathname.substring(1)];
    setCurrentKey(getCurrentKey(newKeys));
  }, [pathname]);

  return loading || !currentUser ? null : (
    <Wrapper>
      <Sider style={sideBarStyle} width={230}>
        <Menu
          mode="inline"
          style={menuStyle}
          onClick={handleClick}
          onOpenChange={handleOpenKeyChange}
          items={MENU_OPTIONS}
          openKeys={openKeys}
          selectedKeys={currentKey}
        />
      </Sider>
    </Wrapper>
  );
}

//
const parentPath = (pathname) => {
  if (!pathname || typeof pathname !== "string") return "/";

  const [path] = pathname.split("/").filter(Boolean);
  return path;
};

const getCurrentKey = (keys, curKey) => {
  if (!keys || !Array.isArray(keys) || keys.length === 0) return [];
  const [parent] = keys || [];

  const subMenu = MENU.reduce((acc, { children }) => {
    acc.push(...(children || []));

    return acc;
  }, []);

  const currentKey = subMenu
    .filter(({ key }) =>
      curKey ? key === curKey : key.startsWith(parent + "_"),
    )
    .map(({ key }) => key);

  return [parent, ...currentKey];
};

const sideBarStyle = {
  left: 0,
  top: 30,
  bottom: 0,
  paddingTop: 30,
  height: "100vh",
  overflow: "auto",
  position: "fixed",
  backgroundColor: "rgba(241, 242, 244, 1)",
};

const menuStyle = {
  height: "100%",
  paddingTop: 40,
  marginTop: -30,
  backgroundColor: "rgba(241, 242, 244, 1)",
};

const Wrapper = styled.div`
  .ant-layout-sider-children {
    height: 100%;
  }

  .ant-menu-submenu-title {
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100% - 20px) !important;

    a,
    a:hover {
      color: inherit;
    }
  }
`;
