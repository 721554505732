import UpdateUserImpl from "../user/updateUserImpl";
import React from "react";
import { useAppContext } from "../../context";

export default function Profile({ open, onCancel }) {
  const { currentUser } = useAppContext();

  return (
    <UpdateUserImpl
      title="Profile"
      value={currentUser}
      open={open}
      onCancel={onCancel}
      fromProfile
    />
  );
}
