import { Button, Upload } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import Icons from "../../icons";
import { useCreateFile } from "./hooks";

const UploadFile = ({
  value,
  onChange,
  singleUpload,
  showUploadList,
  listType,
  disabled,
  multiple,
  accept,
}) => {
  const { notification } = useAppContext();

  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    disabled: false,
    fileList: [],
  });
  const [customRequest] = useCreateFile();

  React.useEffect(() => {
    if (value && value.length) {
      const fileList = value.map((v, index) => {
        return {
          uid: `${index}`,
          name: v.name,
          status: "done",
          url: v.thumbnailUrl ? v.thumbnailUrl : v.url,
          id: value.id,
        };
      });
      setState({ fileList });
    }
  }, [value]);

  const beforeUpload = React.useCallback((file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({ message: "You can only upload JPG/PNG file!" });
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      notification.error({ message: "Image must smaller than 20MB!" });
    }
    return isJpgOrPng && isLt2M;
  }, [notification]);

  const onChangeFn = React.useCallback(
    (v) => {
      const files = [];
      setState({ fileList: v.fileList });

      if (v?.fileList?.length > 0) {
        for (let file of v.fileList) {
          if (file?.status === "done") {
            files.push(file.response);
          }
        }
      }

      onChange?.(files);
    },
    [onChange],
  );

  const props = React.useMemo(() => {
    return {
      name: "file",
      accept: accept ?? null,
      fileList: state.fileList,
      customRequest,
      onChange: onChangeFn,
    };
  }, [accept, customRequest, state.fileList, onChangeFn]);

  return (
    <Wrapper>
      {singleUpload ? (
        <Upload
          showUploadList={showUploadList}
          beforeUpload={beforeUpload}
          listType="picture-card"
          {...props}
        >
          <div>
            <Icons.Plus width={20} height={20} />
            <p className="ant-upload-text">Upload</p>
          </div>
        </Upload>
      ) : (
        <Upload.Dragger
          listType={listType}
          disabled={disabled}
          multiple={multiple}
          {...props}
        >
          <div>
            <Button type="primary" children="Add images" />
            <p className="ant-upload-text">Or drop image to upload</p>
          </div>
        </Upload.Dragger>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 0;

  .ant-upload {
    &-btn {
      padding: 20px 0;
    }

    &-text {
      margin-top: 5px;
    }
  }
`;

export default UploadFile;
