import { gql } from '@apollo/client';
import { BaseFrag } from '../fragment';

const PRODUCT_BASES = gql`
  query productBases($filter: ProductBaseFilter!) {
    productBases(filter: $filter) {
      total
      nodes {
        ...BaseFrag
      }
    }
  }
  ${BaseFrag}
`;

const PRODUCT_BASE_BY_ID = gql`
  query productBaseById($id: ID!) {
    productBaseById(id: $id) {
      ...BaseFrag
    }
  }
  ${BaseFrag}
`;

export { PRODUCT_BASES, PRODUCT_BASE_BY_ID };