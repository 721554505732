const checkMimeType = (file, cb) => {
  const mimes = [
    {
      mime: "image/png",
      pattern: [0x89, 0x50, 0x4e, 0x47],
    },
    {
      mime: "image/jpeg",
      pattern: [0xff, 0xd8, 0xff],
    },
    {
      mime: "image/gif",
      pattern: [0x47, 0x49, 0x46, 0x38],
    },
    {
      mime: "image/webp",
      pattern: [
        0x52,
        0x49,
        0x46,
        0x46,
        undefined,
        undefined,
        undefined,
        undefined,
        0x57,
        0x45,
        0x42,
        0x50,
        0x56,
        0x50,
      ],
    },
  ];
  const isMime = (bytes, mime) => {
    return mime.pattern.every((p, i) => !p || bytes[i] === p);
  };
  const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
  const blob = file.slice(0, numBytesNeeded);
  const reader = new FileReader();
  reader.onloadend = (e) => {
    if (!e || !reader.result) return cb(null);
    const bytes = new Uint8Array(reader.result);
    const mime = mimes.find((m) => isMime(bytes, m));
    return cb(mime ? mime.mime : null);
  };
  reader.readAsArrayBuffer(blob);
};

const getFileName = (defaultVal, mime) => {
  let fileName = defaultVal;
  if (mime) {
    switch (mime) {
      case "image/webp":
        fileName = fileName.replace(/\.[^/.]+$/, ".webp");
        break;
      case "image/gif":
        fileName = fileName.replace(/\.[^/.]+$/, ".gif");
        break;
      case "image/jpeg":
        fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
        break;
      case "image/png":
        fileName = fileName.replace(/\.[^/.]+$/, ".png");
        break;
      default:
        break;
    }
  }

  return fileName;
};

export { checkMimeType, getFileName };
