import { gql } from "@apollo/client";
import { MappingOrderFrag, OrderFrag } from "../fragment";

const UPLOAD_ORDER_DESIGNS = gql`
  mutation uploadOrderDesigns($id: ID!, $designs: [UpdateOrderDesign!]!) {
    uploadOrderDesigns(id: $id, designs: $designs) {
      id
    }
  }
`;

const UPDATE_REVENUE = gql`
  mutation updateRevenue($input: UpdateRevenue!) {
    updateRevenue(input: $input)
  }
`;

const CREATE_MANUAL_ORDER = gql`
  mutation createManualOrder($input: NewManualOrder!) {
    createManualOrder(input: $input) {
      id
    }
  }
`;

const MAPPING_PRODUCT_BASE = gql`
  mutation mappingProductBase($input: NewMappingProductBase!) {
    mappingProductBase(input: $input) {
      id
    }
  }
`;

const MAPPING_PRODUCT_EXISTS = gql`
  mutation mappingProductExists($input: NewMappingProductExist!) {
    mappingProductExists(input: $input) {
      id
    }
  }
`;

const UPDATE_MAPPING_ORDER_QTY = gql`
  mutation updateMappingOrderQty($id: ID!, $qty: Int!) {
    updateMappingOrderQty(id: $id, qty: $qty) {
      ...MappingOrderFrag
    }
  }
  ${MappingOrderFrag}
`;

const CLONE_MAPPING_ORDER = gql`
  mutation cloneMappingOrder($id: ID!) {
    cloneMappingOrder(id: $id) {
      ...MappingOrderFrag
    }
  }
  ${MappingOrderFrag}
`;

const DELETE_MAPPING_ORDER = gql`
  mutation deleteMappingOrder($id: ID!, $isIgnore: Boolean!) {
    deleteMappingOrder(id: $id, isIgnore: $isIgnore)
  }
`;

const REVERT_ORDER_TO_MAPPING = gql`
  mutation revertOrderToMapping($id: ID!) {
    revertOrderToMapping(id: $id)
  }
`;

const RESEND_ORDER = gql`
  mutation resendOrder($id: ID!, $isResendRelatedOrder: Boolean!) {
    resendOrder(id: $id, isResendRelatedOrder: $isResendRelatedOrder)
  }
`;

const CHANGE_ORDER_PRODUCT = gql`
  mutation changeOrderProduct($id: ID!, $productVariantId: ID!) {
    changeOrderProduct(id: $id, productVariantId: $productVariantId) {
      ...OrderFrag
    }
  }
  ${OrderFrag}
`;

const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!, $isCancelRelatedOrder: Boolean!) {
    cancelOrder(id: $id, isCancelRelatedOrder: $isCancelRelatedOrder) {
      id
      status
    }
  }
`;

const UPDATE_ORDER_SHIPPING = gql`
  mutation updateOrderShipping($id: ID!, $shippingAddress: NewAddress!) {
    updateOrderShipping(id: $id, shippingAddress: $shippingAddress) {
      ...OrderFrag
    }
  }
  ${OrderFrag}
`;

const UPDATE_ORDER_PERSONALIZED_FIELDS = gql`
  mutation updateOrderPersonalizedFields(
    $id: ID!
    $fields: [NewPersonalizedField!]!
  ) {
    updateOrderPersonalizedFields(id: $id, fields: $fields) {
      id
      fields {
        title
        value
      }
    }
  }
`;

const PAUSE_ORDER = gql`
  mutation pauseOrder($id: ID!, $isPause: Boolean!) {
    pauseOrder(id: $id, isPause: $isPause) 
  }
`;

const REMOVE_FULFILLMENT_STATUS = gql`
  mutation removeFulfillmentStatus($id: ID!) {
    removeFulfillmentStatus(id: $id)
  }
`;

export {
  UPLOAD_ORDER_DESIGNS,
  UPDATE_REVENUE,
  CREATE_MANUAL_ORDER,
  MAPPING_PRODUCT_BASE,
  MAPPING_PRODUCT_EXISTS,
  UPDATE_MAPPING_ORDER_QTY,
  CLONE_MAPPING_ORDER,
  DELETE_MAPPING_ORDER,
  REVERT_ORDER_TO_MAPPING,
  RESEND_ORDER,
  CHANGE_ORDER_PRODUCT,
  CANCEL_ORDER,
  UPDATE_ORDER_SHIPPING,
  UPDATE_ORDER_PERSONALIZED_FIELDS,
  PAUSE_ORDER,
  REMOVE_FULFILLMENT_STATUS,
};
