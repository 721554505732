import { gql } from "@apollo/client";
import { FulfillmentFrag, FulfillmentProductFrag } from "../fragment";

const FULFILLMENT_BY_ID = gql`
  query fulfillmentById($fulfillmentId: ID!) {
    fulfillmentById(fulfillmentId: $fulfillmentId) {
      ...FulfillmentFrag
    }
  }
  ${FulfillmentFrag}
`;

const FULFILLMENT_OPTIONS = gql`
  query fulfillmentOptions($fulfillmentId: String) {
    fulfillmentOptions(fulfillmentId: $fulfillmentId)
  }
`;

const FULFILLMENTS = gql`
  query fulfillments {
    fulfillments {
      ...FulfillmentFrag
    }
  }
  ${FulfillmentFrag}
`;

const FULFILLMENT_PRODUCTS = gql`
  query fulfillmentProducts($fulfillmentId: String!) {
    fulfillmentProducts(fulfillmentId: $fulfillmentId) {
      ...FulfillmentProductFrag
    }
  }
  ${FulfillmentProductFrag}
`;

export {
  FULFILLMENT_OPTIONS,
  FULFILLMENT_BY_ID,
  FULFILLMENTS,
  FULFILLMENT_PRODUCTS,
};
