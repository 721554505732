import Filter from "../filter";
import RadioFilter from "../filter/radio";
import MoreFilter from "../more-filter";
import { ROLES } from "../../constants";
import { Card, Space } from "antd";
import React from "react";

const ROLE_OPTIONS = [
  { value: ROLES.Seller, label: ROLES.Seller },
  { value: ROLES.Designer, label: ROLES.Designer },
];
const STATUS_OPTIONS = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export function UserFilter({ onChange, handleClearFilter }) {
  const handleFilterChange = React.useCallback(
    (key) => (value) => {
      let newVal = [null, "all"].includes(value) ? null : value;
      if (newVal) {
        if (key === "roles") {
          newVal = [newVal];
        }
      }

      onChange({ [key]: newVal });
    },
    [onChange],
  );

  const moreFilterMarkup = React.useMemo(() => {
    const children = (
      <Space direction="vertical" size="large">
        <RadioFilter
          label="Role"
          onChange={handleFilterChange("roles")}
          options={ROLE_OPTIONS}
        />
        <RadioFilter
          label="Status"
          onChange={handleFilterChange("status")}
          options={STATUS_OPTIONS}
        />
      </Space>
    );

    return (
      <MoreFilter children={children} handleClearFilter={handleClearFilter} />
    );
  }, [handleFilterChange, handleClearFilter]);

  return (
    <Card>
      <Filter
        onChange={handleFilterChange("search")}
        moreFilter={moreFilterMarkup}
      />
    </Card>
  );
}
