import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { UPDATE_USER } from "../../graphql/mutation";
import UserForm from "../shared/user-form";
import { useUserContext } from "./context";

function UpdateUserImpl({ open, onCancel, title, value, fromProfile }) {
  const { id } = value || {};
  const { notification } = useAppContext();
  const userCtx = useUserContext();

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (res) => {
      if (res?.updateUser) {
        notification.success({ message: "Update successfully" });
        userCtx?.refetch?.();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!id) return;
      updateUser({
        variables: {
          input: { id, ...values },
        },
      }).finally(() => {
        onCancel();
      });
    },
    [id, updateUser, onCancel],
  );

  const actionsMarkup = React.useMemo(() => {
    return (
      <div className="actions">
        <Button children="Cancel" onClick={onCancel} />
        <Button
          children="Update"
          type="primary"
          htmlType="submit"
          loading={loading}
        />
      </div>
    );
  }, [onCancel, loading]);

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <Wrapper>
        <UserForm
          onFinish={handleFinish}
          value={value}
          submitLabel="Update"
          footer={actionsMarkup}
          fromProfile={fromProfile}
        />
      </Wrapper>
    </Modal>
  );
}

export default UpdateUserImpl;

const Wrapper = styled.div`
  margin-top: 16px;

  .actions {
    display: flex;
    justify-content: end;
    column-gap: 12px;
  }
`;
