import { gql } from "@apollo/client";
import { TrackingFrag } from "../fragment";

const CREATE_TRACKING = gql`
  mutation createTracking($input: NewTracking!) {
    createTracking(input: $input) {
      ...TrackingFrag
    }
  }
  ${TrackingFrag}
`;

const UPDATE_TRACKING = gql`
  mutation updateTracking($input: EditTracking!) {
    updateTracking(input: $input) {
      ...TrackingFrag
    }
  }
  ${TrackingFrag}
`;

const EXPORT_AMAZON_TRACKING = gql`
  mutation exportAmazonTracking($storeId: ID!) {
    exportAmazonTracking(storeId: $storeId)
  }
`;

const RE_GEN_AMAZON_TRACKING_FILE = gql`
  mutation regenAmazonTrackingFile($id: ID!) {
    regenAmazonTrackingFile(id: $id)
  }
`;

export {
  CREATE_TRACKING,
  UPDATE_TRACKING,
  EXPORT_AMAZON_TRACKING,
  RE_GEN_AMAZON_TRACKING_FILE,
};
