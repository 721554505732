import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import Icons from "../../icons";

const Wrapper = styled.div`
  padding: 5px;
  border: 1px solid #c4cdd5;
  display: flex;
  border-radius: 3px;
  align-items: center;
  column-gap: 5px;

  .ant-btn {
    padding-top: 1px !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  input {
    border: 0 none;
    flex-grow: 1;
    outline: 0 none;
    &:active,
    &:focus {
      outline: 0 none;
      border: 0 none;
    }
  }
`;

export default function CopyToClipboard({ text, type }) {
  const inputRef = React.useRef();
  const { notification } = useAppContext();

  const handleClick = React.useCallback(() => {
    inputRef.current.focus();
    inputRef.current.setSelectionRange(0, inputRef.current.value.length);

    // Case type is `password`
    navigator.clipboard?.writeText?.(inputRef.current.value);
    document.execCommand("copy");
    notification.info({ message: "Copied" });
  }, [notification]);

  return (
    <Wrapper>
      <input
        ref={inputRef}
        value={text}
        type={type ?? "text"}
        onChange={() => {}}
      />
      <Button
        size="small"
        type="text"
        title="Copy to clipboard"
        onClick={handleClick}
        icon={<Icons.Copy width={18} height={18} />}
      />
    </Wrapper>
  );
}
