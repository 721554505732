import { gql } from '@apollo/client';

const StoreFrag = gql`
  fragment StoreFrag on Store {
    id
    createdAt
    title
    platform
    status
    email
    apiKey    
  }
`;

export { StoreFrag }