import { useMutation } from "@apollo/client";
import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { DELETE_FILE } from "../../../graphql/mutation";
import { formatFileSize } from "../../../utils";

const Container = styled.div`
  .thumbnail {
    min-height: 100px;
    background: rgb(214, 224, 233);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: rgb(137, 157, 173);
  }

  .danger-btn {
    color: rgb(219, 27, 70);
    padding: 0;
  }

  .desc {
    font-weight: 500;
  }
`;

const FileDetail = ({ value, onDeleted }) => {
  const { notification } = useAppContext();
  const [deleteFile, { loading }] = useMutation(DELETE_FILE, {
    onCompleted: (res) => {
      if (res?.deleteFile) {
        notification.success({ message: "Delete file successfully" });
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleDelete = React.useCallback(() => {
    if (!value.id) return;

    deleteFile({
      variables: {
        id: value.id,
      },
    });

    onDeleted(value);
  }, [value, deleteFile, onDeleted]);

  return (
    <Container>
      <div>
        <div className={"thumbnail"}>
          <img
            style={{ width: "100%" }}
            alt={value.thumbnailUrl}
            src={value.thumbnailUrl ? value.thumbnailUrl : value.url}
          />
        </div>
        <div className={"desc"}>
          <p>{value.name}</p>
          <p>{formatFileSize(value.size)}</p>
          <p>
            <Button
              onClick={handleDelete}
              className={"danger-btn"}
              type={"link"}
              loading={loading}
            >
              Delete Permanently
            </Button>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default FileDetail;
