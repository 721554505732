import { useMutation } from "@apollo/client";
import React from "react";
import { useAppContext } from "../../context";
import { CREATE_STORE } from "../../graphql/mutation";
import { StoreForm } from "./storeForm";

export function CreateStore({ open, onCancel, refetch }) {
  const { notification } = useAppContext();

  const [create, { loading }] = useMutation(CREATE_STORE, {
    onCompleted: (res) => {
      if (res?.createStore) {
        notification.success({ message: "Create store successfully" });
        onCancel?.();
        refetch?.();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!values || typeof values !== "object") return;

      create({
        variables: {
          input: values,
        },
      });
    },
    [create],
  );

  return (
    <StoreForm
      title="Create store"
      open={open}
      onCancel={onCancel}
      loading={loading}
      onFinish={handleFinish}
    />
  );
}
