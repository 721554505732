import { useQuery } from "@apollo/client";
import { Button, Card, Row, Space, Typography } from "antd";
import React from "react";
import LinkComp from "../../components/link";
import { ProductContextProvider } from "../../components/product/context";
import Suspense from "../../components/shared/suspense";
import { PRODUCTS } from "../../graphql/query";

const ProductFilter = React.lazy(() =>
  import("../../components/product/filter"),
);
const ProductTable = React.lazy(() =>
  import("../../components/product/dataTable"),
);

export default function ProductPage() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      designed: null,
      productBaseId: null,
      personalized: null,
      createTime: null,
      authorId: null,
    }),
    [],
  );

  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(PRODUCTS, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Products" />
        <Button
          children={
            <LinkComp children="Create Product" href="/products/create" />
          }
          type="primary"
        />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Suspense>
          <ProductFilter
            onChange={handleFilterChange}
            filter={filter}
            handleClearFilter={handleClearFilter}
          />
        </Suspense>
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <ProductContextProvider refetch={refetch}>
              <Suspense>
                <ProductTable
                  loading={loading}
                  filter={filter}
                  data={data?.products?.nodes || []}
                  total={data?.products?.total || 0}
                  handlePaginationChange={handlePaginationChange}
                />
              </Suspense>
            </ProductContextProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
