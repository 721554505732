import { useMutation } from "@apollo/client";
import axios from "axios";
import React from "react";
import { CREATE_FILE, CREATE_PRESIGNED_URL } from "../../../graphql/mutation";
import { checkMimeType, getFileName } from "./utils";

export const useCreateFile = () => {
  // Mutations
  const [createPresignedUrl] = useMutation(CREATE_PRESIGNED_URL);
  const [createFile] = useMutation(CREATE_FILE);

  const customRequest = React.useCallback(
    async (options) => {
      const { file, onSuccess, onError } = options;

      checkMimeType(file, async (mime) => {
        let fileType = mime ? mime : file.type;
        let fileName = getFileName(file.name, mime);

        try {
          const input = {
            name: fileName,
            size: file.size,
            mimeType: fileType,
          };

          const res = await createPresignedUrl({
            variables: {
              input,
            },
          });

          const { key, url } = res?.data?.createPresignedUrl || {};
          await axios.put(url, file, {
            headers: { "Content-Type": fileType },
            timeout: 1000 * 60 * 30,
          });

          const resCreateFile = await createFile({
            variables: {
              input: {
                ...input,
                key,
              },
            },
          });

          onSuccess(resCreateFile?.data?.createFile);
        } catch (e) {
          onError(e);
        }
      });
    },
    [createFile, createPresignedUrl],
  );

  return [customRequest];
};
