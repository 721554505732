import get from "lodash.get";
import { API_DOMAIN } from "../config/config";
import { ROLES } from "../constants";
import ls from "../security";

const TOKEN_KEY = "qdecom";

export const getTokenObject = () => {
  return ls.get(TOKEN_KEY);
};

export const setTokenObject = (obj) => {
  if (!obj) {
    ls.remove(TOKEN_KEY);
  } else {
    ls.set(TOKEN_KEY, obj);
  }
};

export const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const invalidArr = (arr) => {
  return !arr || !Array.isArray(arr) || arr.length === 0;
};

export const invalidOb = (ob) => {
  return !ob || typeof ob !== "object" || Object.keys(ob).length === 0;
};

export const getSrc = (img) => {
  const src = img?.thumbnailUrl ? img.thumbnailUrl : img?.url ? img.url : null;
  return src;
};

const checkRoleState = {
  isAdmin: false,
  isSeller: false,
  isDesigner: false,
};

export const checkRole = (user) => {
  if (invalidOb(user)) return checkRoleState;

  const roles = get(user, "roles");
  if (invalidArr(roles)) return checkRoleState;

  const fn = (role) => {
    return roles.includes(role);
  };

  return {
    isAdmin: fn(ROLES.Admin),
    isSeller: fn(ROLES.Seller),
    isDesigner: fn(ROLES.Designer),
  };
};

export const validRoles = (obj, target) => {
  if (invalidArr(obj) || invalidArr(target)) return false;
  return target.some((r) => obj.includes(r));
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const isImage = (url) => {
  if (!url) return false;

  return !!(url || "").match(new RegExp(API_DOMAIN, "gi"));
};
