import { gql } from '@apollo/client';
import { ProductFrag } from '../fragment';

const PRODUCTS = gql`
  query products($filter: ProductFilter!) {
    products(filter: $filter) {
      total
      nodes {
        ...ProductFrag
      }
    }
  }
  ${ProductFrag}
`;

const PRODUCT_BY_ID = gql`
  query productByID($id: ID!) {
    productByID(id: $id) {
      ...ProductFrag
    }
  }
  ${ProductFrag}
`;

export { PRODUCTS, PRODUCT_BY_ID };