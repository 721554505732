import { gql } from "@apollo/client";

const TaskFrag = gql`
  fragment TaskFrag on Task {
    id
    createdAt
    order {
      id
      originId
      store {
        platform
        title
      }
      fields {
        title
        value
      }
      amzPersonalizedPreview
      productStoreUrl
      productVariant {
        productBaseVariant {
          attributes {
            name
            option
          }
        }
      }
      product {
        images {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
      }
    }
    product {
      id
      title
      sku
      personalized
      designPositions {
        designPosition {
          name
          description
        }
      }
      productBases {
        id
        title
      }
      psd
      psdFiles {
        id
        url
        thumbnailUrl
      }
    }
    assignee {
      id
      fullName
    }
    status
    doneDate
    assignDate
    note
  }
`;

const TaskAggregationFrag = gql`
  fragment TaskAggregationFrag on TaskAggregation {
    all
    unAssigned
    assigned
    pending
    doing
    needReview
    rejected
    done
    cancel
  }
`;

const TaskSubmissionFrag = gql`
  fragment TaskSubmissionFrag on TaskSubmission {
    id
    createdAt
    taskId
    status
    rejectReason
    designs {
      file {
        id
        thumbnailUrl
        url
      }
      url
      designPosition {
        id
        name
        description
      }
    }
  }
`;

const TaskTimelineFrag = gql`
  fragment TaskTimelineFrag on TaskTimeline {
    createdAt
    message
    author {
      id
      fullName
    }
  }
`;

export { TaskFrag, TaskAggregationFrag, TaskSubmissionFrag, TaskTimelineFrag };
