import React from "react";
const TaskContext = React.createContext(null);

const TaskContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <TaskContext.Provider value={bag}>{children}</TaskContext.Provider>;
};

const useTaskContext = () => {
  return React.useContext(TaskContext);
};

export { TaskContextProvider, useTaskContext };
