import React from "react";
const BaseContext = React.createContext(null);

const BaseContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <BaseContext.Provider value={bag}>{children}</BaseContext.Provider>;
};

const useBaseContext = () => {
  return React.useContext(BaseContext);
};

export { BaseContextProvider, useBaseContext };
