import { useMutation } from "@apollo/client";
import { Modal } from "antd";
import React from "react";
import { useAppContext } from "../../context";
import { DELETE_FULFILLMENT_CONFIGURE } from "../../graphql/mutation";
import { useFulfillmentContext } from "./context";

export default function UninstallFulfillment({
  open,
  onCancel,
  name,
  fulfillmentId,
}) {
  const { notification } = useAppContext();
  const { refetch } = useFulfillmentContext();

  // Mutation
  const [uninstall, { loading }] = useMutation(DELETE_FULFILLMENT_CONFIGURE, {
    onCompleted: (res) => {
      if (res?.deleteFulfillmentConfigure) {
        notification.success({
          message: "Uninstall successfully.",
        });
        onCancel?.();
        refetch?.();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(() => {
    if (fulfillmentId != null) {
      uninstall({
        variables: {
          fulfillmentId,
        },
      });
    }
  }, [uninstall, fulfillmentId]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={handleFinish}
      okText="Submit"
      title={`Uninstall ${name}`}
      confirmLoading={loading}
    >
      <p>Are you sure to delete this fulfillment configure?</p>
    </Modal>
  );
}
