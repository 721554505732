import { useRouteChangeContext } from "../route-change";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

function isModifiedEvent(event) {
  const eventTarget = event.currentTarget;
  const target = eventTarget.getAttribute("target");

  return (
    (target && target !== "_self") ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey ||
    (event.nativeEvent && event.nativeEvent.button === 2)
  );
}

const LinkComp = forwardRef(({ href, onClick, ...rest }, ref) => {
  const useLink = href && href.startsWith("/");
  const { onRouteChangeStart } = useRouteChangeContext();
  if (!useLink)
    return (
      <a
        href={href}
        onClick={onClick}
        {...rest}
        children={rest.children || null}
      />
    );

  return (
    <Link
      to={href}
      onClick={(event) => {
        if (!isModifiedEvent(event)) {
          const { pathname, search, hash } = window.location;
          const hrefCurrent = `${pathname}${search}${hash}`;
          const hrefTarget = href;

          if (hrefTarget !== hrefCurrent) {
            onRouteChangeStart();
          }
        }
        if (onClick) onClick(event);
      }}
      {...rest}
      ref={ref}
    />
  );
});

export default LinkComp;
