import { useMutation } from "@apollo/client";
import { Avatar, Button, Popconfirm, Space, Table, Tag } from "antd";
import React from "react";
import { STORE_STATUS } from "../../constants";
import { useAppContext } from "../../context";
import { DELETE_STORE, UPDATE_STORE } from "../../graphql/mutation";
import { useToggle } from "../../hooks";
import Icons from "../icons";
import CopyToClipboard from "../shared/copyToClipboard";
import FormatTime from "../shared/formatTime";
import { StoreForm } from "../store/storeForm";
import { useStoreContext } from "./context";

export function TableStore({
  data,
  loading,
  filter,
  total,
  handlePaginationChange,
}) {
  return (
    <Table
      dataSource={data}
      columns={columns}
      sticky
      loading={loading}
      rowKey={({ id }) => id}
      scroll={{ x: columns.reduce((acc, cur) => acc + (cur.width || 0), 0) }}
      pagination={{
        total,
        pageSize: filter?.limit || 20,
        current: filter.paged || 1,
        onChange: (paged, limit) => handlePaginationChange({ paged, limit }),
      }}
    />
  );
}

const BADGE_STATUS = {
  [STORE_STATUS.Connected]: "success",
  [STORE_STATUS.Suspended]: "error",
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 200,
  },
  {
    title: "Account",
    key: "account",
    width: 350,
    render: (_, { title, email }) => {
      return (
        <Space>
          <Avatar
            size="large"
            icon={<Icons.User />}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <Space direction="vertical" size={2}>
            <span>{title}</span>
            <span>{email}</span>
          </Space>
        </Space>
      );
    },
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    width: 200,
  },
  {
    title: "API Key",
    key: "api-key",
    width: 300,
    render: (_, { apiKey }) => <CopyToClipboard text={apiKey} />,
  },
  {
    title: "status",
    key: "status",
    width: 150,
    render: (_, { status }) => {
      const statusMarkup = (
        <Tag color={BADGE_STATUS[status]}>
          <span>{status}</span>
        </Tag>
      );
      return statusMarkup;
    },
  },
  {
    title: "Created At",
    key: "createdAt",
    width: 120,
    render: (_, { createdAt }) => <FormatTime data={createdAt} />,
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    fixed: "right",
    width: 170,
    render: (_, record) => {
      return (
        <Space size="small">
          <Edit store={record} />
          <Delete store={record} />
        </Space>
      );
    },
  },
];

const Edit = ({ store }) => {
  const { id } = store;
  const [open, toggleOpen] = useToggle(false);

  const { notification } = useAppContext();
  const [update, { loading }] = useMutation(UPDATE_STORE, {
    onCompleted: (res) => {
      if (res?.updateStore) {
        notification.success({ message: "Update store successfully." });
        toggleOpen();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFinish = React.useCallback(
    (values) => {
      if (!id || !values || typeof values !== "object") return;
      update({
        variables: {
          input: {
            id,
            ...values,
          },
        },
      });
    },
    [update, id],
  );

  return (
    <React.Fragment>
      <Button children="Edit" type="link" onClick={toggleOpen} />
      <StoreForm
        title="Update store"
        btnLabel="Update"
        open={open}
        value={store}
        loading={loading}
        onCancel={toggleOpen}
        onFinish={handleFinish}
      />
    </React.Fragment>
  );
};

const Delete = ({ store }) => {
  const { id } = store;
  const { notification } = useAppContext();
  const { refetch } = useStoreContext();

  const [open, toggleOpen] = useToggle(false);
  const [deleteStore, { loading }] = useMutation(DELETE_STORE, {
    onCompleted: (res) => {
      if (res?.deleteStore) {
        notification.success({ message: "Delete store successfully." });
        toggleOpen();
        refetch?.();
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });
  const onConfirm = React.useCallback(() => {
    if (!id) return;

    deleteStore({
      variables: {
        id,
      },
    });
  }, [id, deleteStore]);

  return (
    <Popconfirm
      open={open}
      title="Delete store"
      description="Are you sure to delete this store?"
      onConfirm={onConfirm}
      onCancel={toggleOpen}
      okText="Yes"
      cancelText="Cancel"
      okButtonProps={{ loading, danger: true }}
    >
      <Button danger children="Delete" type="link" onClick={toggleOpen} />
    </Popconfirm>
  );
};
