import { App } from "antd";
import React from "react";

const AppContext = React.createContext({
  loading: false,
  currentUser: null,
  authenticate: () => {},
  notification: {},
  message: {},
  modal: {},
});

const AppProvider = ({ children, ...props }) => {
  const staticFn = App.useApp();
  const bag = React.useMemo(() => {
    return { ...staticFn, ...props };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(props), ...Object.values(staticFn)]);

  return <AppContext.Provider value={bag}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  return React.useContext(AppContext);
};

export { AppProvider, useAppContext };
