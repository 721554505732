import { Button, Upload } from "antd";
import React from "react";
import styled from "styled-components";
import { IMAGE_TYPES } from "../../../constants";
import { useToggle } from "../../../hooks";
import MediaModal from "../media-modal";
import { useCreateFile } from "../media/hooks";
import PreviewFile from "../media/previewFile";

function MediaButton(
  { value, onChange, listType, accept, multiple, singleUpload, width, name },
  _ref,
) {
  const [open, toggleOpen] = useToggle(false);
  const [customRequest] = useCreateFile();

  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    fileList: [],
    value: [],
    previewImage: "",
    previewVisible: false,
  });

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handlePreview = React.useCallback(async (file) => {
    if (file.file && !file.file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    let currentMimeType = true;
    let currentFile = "";
    if (file && file.file && file.file.mimeType) {
      if (!IMAGE_TYPES.includes(file.file.mimeType.toLowerCase())) {
        currentMimeType = false;
        currentFile = file.file;
      }
    }
    setState({
      previewImage: (file.file && file.file.url) || file.preview,
      previewUrl: file?.file?.url,
      previewVisible: true,
      isTypeImage: currentMimeType,
      currentFile: currentFile,
    });
  }, []);

  const getFileList = React.useCallback((files = []) => {
    return files.filter(Boolean).map((file, index) => {
      let isDone = index === 0;

      return {
        file: file,
        uid: file.id,
        name: file.name,
        status: isDone ? "done main-img" : "done",
        url: file.thumbnailUrl ? file.thumbnailUrl : file.url,
      };
    });
  }, []);

  const handleDrag = React.useCallback(
    (e) => {
      const newValue = state.value;
      for (let file of e.fileList) {
        if (file?.response) {
          newValue.push(file.response);
        }
      }
      setState({ fileList: e.fileList, value: newValue });

      let newFiles = getFileList(e.fileList);
      newFiles = newValue?.length > 0 ? [...newFiles, ...newValue] : newFiles;
      onChange?.(newFiles.filter(Boolean));
    },
    [state.value, getFileList, onChange],
  );

  const handleRemove = React.useCallback(() => {}, []);
  const handleCancel = React.useCallback(() => {
    setState({ previewVisible: false });
  }, []);

  const handleSetFile = React.useCallback(() => {
    const files = state.value;
    let v = [];
    for (let i = 0; i < files.length; i++) {
      if (!(value || []).find((f) => f.id === files[i].id)) {
        v.push(files[i]);
      }
    }
    let newValue = multiple ? [...(value || []), ...v] : [...v];
    toggleOpen();
    setState({
      fileList: getFileList(newValue.length > 1 ? [newValue[1]] : newValue),
      value: [],
    });
    let val = newValue;
    if (!multiple) {
      val = [newValue[0]];
    }

    onChange?.(val);
  }, [value, state.value, multiple, toggleOpen, getFileList, onChange]);

  const handleMediaChange = React.useCallback((value) => {
    setState({ value });
  }, []);

  React.useEffect(() => {
    if (value?.length > 0) {
      setState({ fileList: getFileList(value) });
    }
  }, [value, getFileList]);

  let disabled = false;
  if (!multiple && state.fileList.length) {
    disabled = true;
  }

  return (
    <Wrapper
      width={width}
      className={`container ${disabled ? "disabled" : "enabled"} ${
        !!multiple ? "multiple" : ""
      }`}
    >
      <Upload.Dragger
        onChange={handleDrag}
        onPreview={handlePreview}
        fileList={state.fileList}
        listType={listType}
        openFileDialogOnClick={false}
        customRequest={customRequest}
        showUploadList={true}
        accept={accept}
        singleUpload={singleUpload}
        multiple={multiple}
        onRemove={handleRemove}
      >
        <div className="media-btn">
          <Button
            type="link"
            size="small"
            children={name ? name : "Media Library"}
            onClick={toggleOpen}
          />
          <p className="">Or drop image to upload</p>
        </div>
      </Upload.Dragger>
      <MediaModal
        open={open}
        onCancel={toggleOpen}
        value={state.value}
        onChange={handleMediaChange}
        onSetFile={handleSetFile}
        multiple={multiple}
      />
      <PreviewFile
        open={state.previewVisible}
        onCancel={handleCancel}
        previewImage={state.previewImage}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: ${(props) => (props.width ? "100px" : "auto")};

  .media-btn {
    padding: 6px 0;
  }

  &.disabled {
    .ant-upload {
      display: none;
    }
  }

  &.multiple .ant-upload-list-picture-card {
    margin-top: 1rem;
  }
`;

export default React.forwardRef(MediaButton);
