import { useQuery } from "@apollo/client";
import { Card, Row, Space, Typography } from "antd";
import React from "react";
import Suspense from "../../components/shared/suspense";
import { TaskContextProvider } from "../../components/task/context";
import { TASKS } from "../../graphql/query";

const TaskFilter = React.lazy(() => import("../../components/task/filter"));
const TaskTable = React.lazy(() => import("../../components/task/dataTable"));
const TaskTab = React.lazy(() => import("../../components/task/tab"));

export default function TaskPage() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      productBaseId: null,
      storeID: null,
      designId: null,
      status: null,
      platform: null,
      isAssigned: null,
    }),
    [],
  );

  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(TASKS, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  const handleStatusChange = React.useCallback((status, isAssigned) => {
    setFilter({ status, isAssigned });
  }, []);

  const tabRef = React.useRef(null);
  const customRefetch = React.useCallback(() => {
    tabRef.current?.refetch();
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Tasks" />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Suspense>
          <TaskFilter
            onChange={handleFilterChange}
            filter={filter}
            handleClearFilter={handleClearFilter}
          />
        </Suspense>
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <TaskContextProvider refetch={customRefetch}>
              <Suspense>
                <TaskTab
                  onChange={handleStatusChange}
                  filter={filter}
                  ref={tabRef}
                />
                <TaskTable
                  loading={loading}
                  data={data?.tasks?.nodes || []}
                  total={data?.tasks?.total || 0}
                  handlePaginationChange={handlePaginationChange}
                  filter={filter}
                />
              </Suspense>
            </TaskContextProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
