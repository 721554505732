import React from "react";
const FulfillmentContext = React.createContext(null);

const FulfillmentContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <FulfillmentContext.Provider value={bag}>{children}</FulfillmentContext.Provider>;
};

const useFulfillmentContext = () => {
  return React.useContext(FulfillmentContext);
};

export { FulfillmentContextProvider, useFulfillmentContext };
