import React from "react";
const UserContext = React.createContext(null);

const UserContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <UserContext.Provider value={bag}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  return React.useContext(UserContext);
};

export { UserContextProvider, useUserContext };
