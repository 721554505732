import BreadcrumbComp from "../../../components/shared/breadcrumb";
import { Space, Typography } from "antd";
import React from "react";

const CreateProduct = React.lazy(() =>
  import("../../../components/product/createProduct"),
);

export default function CreateProductPage() {
  return (
    <React.Fragment>
      <Space direction="vertical" size="small">
        <BreadcrumbComp />
        <Typography.Title
          level={1}
          children="Create Product"
          style={{ margin: 0 }}
        />
      </Space>
      <CreateProduct />
    </React.Fragment>
  );
}
