import { gql } from '@apollo/client';

const UserFrag2 = gql`
  fragment UserFrag2 on User {
    id
    fullName
    email
    roles
  }
`;

const LOGIN = gql`
  mutation login($email: String!, $password: String!, $remember: Boolean) {
    login(email: $email, password: $password, remember: $remember) {
      id
      expiredAt
      user {
       ...UserFrag2
      }
    }
  }
  ${UserFrag2}
`;

const REGISTER = gql`
  mutation register($input: NewUser!) {
    register(input: $input) {
      ...UserFrag2
    }
  }
  ${UserFrag2}
`;

const LOGOUT = gql`
  mutation logout($token: String!) {
    logout(token: $token)
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: EditUser!) {
    updateUser(input: $input) {
      ...UserFrag2
    }
  }
  ${UserFrag2}
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($userId: ID!, $oldPass: String, $newPass: String!) {
    changePassword(userId: $userId, oldPass: $oldPass, newPass: $newPass)
  }
`;


export { LOGIN, LOGOUT, REGISTER, UPDATE_USER, CHANGE_PASSWORD };