import { useMutation } from "@apollo/client";
import { Button, Card, Form, Input, Space, Typography } from "antd";
import React from "react";
import { FULFILLMENT } from "../../constants";
import { useAppContext } from "../../context";
import { FETCH_FULFILLMENT_PRODUCTS } from "../../graphql/mutation";
import { useToggle } from "../../hooks";
import Icons from "../icons";
import CopyToClipboard from "../shared/copyToClipboard";
import InstallFulfillment from "./install";
import UninstallFulfillment from "./uninstall";

export function FulfillmentSection({ fulfillment }) {
  const { id, name, slug, fulfillmentConfigs } = fulfillment;
  const [fulfillmentConfig] = fulfillmentConfigs || [];
  const { description, apiKey } = fulfillmentConfig || {};
  const installed = fulfillmentConfigs?.length > 0;

  const [open, toggleOpen] = useToggle(false);
  const [showType, setShowType] = React.useState(false);

  const props = React.useMemo(() => {
    return {
      name,
      open,
      fulfillmentId: id,
      onCancel: toggleOpen,
    };
  }, [name, open, toggleOpen, id]);

  const Comp = matchFields[slug];

  const handleClick = React.useCallback(() => {
    setShowType((p) => !p);
  }, []);

  return (
    <Card title={name}>
      <Space direction="vertical" size="middle">
        {description ? <Typography.Text children={description} /> : null}

        {apiKey ? (
          <Space>
            <label>API Key:</label>
            <CopyToClipboard
              text={apiKey}
              type={showType ? "text" : "password"}
            />
            <Button
              icon={<Icons.Eye width={20} height={20} />}
              type="link"
              onClick={handleClick}
              style={{ color: "#007a5c", paddingLeft: 0 }}
            />
          </Space>
        ) : null}

        <Space direction="horizontal">
          <Button
            children={installed ? "Uninstall" : "Install"}
            type="primary"
            onClick={toggleOpen}
          />
          {installed ? <FetchProduct id={id} /> : null}
        </Space>
        {installed ? (
          <UninstallFulfillment {...props} />
        ) : (
          <InstallFulfillment {...props} formFields={Comp ? <Comp /> : null} />
        )}
      </Space>
    </Card>
  );
}

const FetchProduct = ({ id }) => {
  const { notification } = useAppContext();
  const [fetchProduct, { loading }] = useMutation(FETCH_FULFILLMENT_PRODUCTS, {
    onCompleted: (res) => {
      if (res?.fetchFulfillmentProducts) {
        notification.success({
          message: "Fetch product for fullfillment successfully",
        });
      }
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  const handleFetch = React.useCallback(() => {
    if (!id) return;
    fetchProduct({
      variables: {
        fulfillmentId: id,
      },
    });
  }, [id, fetchProduct]);

  return (
    <Button
      children="Fetch product"
      onClick={handleFetch}
      loading={loading}
      disabled={!id}
    />
  );
};

const matchFields = {
  [FULFILLMENT.Customcat.slug]: () => (
    <Form.Item
      name="apiKey"
      label="API Key"
      rules={[{ required: true, message: "Please enter api key" }]}
    >
      <Input placeholder="Enter api key" />
    </Form.Item>
  ),
  [FULFILLMENT.Gearment.slug]: () => (
    <>
      <Form.Item
        name="apiKey"
        label="API Key"
        rules={[{ required: true, message: "Please enter api key" }]}
      >
        <Input placeholder="Enter your api key" />
      </Form.Item>
      <Form.Item
        name="apiSecret"
        label="API Signature"
        rules={[{ required: true, message: "Please enter api signature" }]}
      >
        <Input placeholder="Enter your api apiSecret" />
      </Form.Item>
    </>
  ),
};
