import { gql } from "@apollo/client";
import { BaseVariantFrag, BaseDesignPositionFrag, BaseFrag } from "./base";
import { FileFrag } from "./file";

const ProductVariantFrag = gql`
  fragment ProductVariantFrag on ProductVariant {
    id
    sku
    sorting
    productId
    salePrice
    regularPrice
    productBaseVariantId
    productBaseVariant {
      ...BaseVariantFrag
    }
  }
  ${BaseVariantFrag}
`;

// ProductDesignPosition
const ProductDPFrag = gql`
  fragment ProductDPFrag on ProductDesignPosition {
    id
    productId
    url
    file {
      ...FileFrag
    }
    designPosition {
      ...BaseDesignPositionFrag
    }
  }
  ${BaseDesignPositionFrag}
  ${FileFrag}
`;

const ProductFieldFrag = gql`
  fragment ProductFieldFrag on ProductField {
    title
    type
    sorting
  }
`;

const ProductFrag = gql`
  fragment ProductFrag on Product {
    id
    createdAt
    title
    sku
    description
    status
    designed
    personalized
    psd
    totalSales
    productBases {
      ...BaseFrag
    }
    variants {
      ...ProductVariantFrag
    }
    designPositions {
      ...ProductDPFrag
    }
    fields {
      ...ProductFieldFrag
    }
    images {
      id
      sorting
      isMain
      file {
        ...FileFrag
      }
    }
    psdFiles {
      ...FileFrag
    }
  }
  ${BaseFrag}
  ${ProductVariantFrag}
  ${ProductDPFrag}
  ${ProductFieldFrag}
  ${FileFrag}
`;

export { ProductFrag };
