import React from "react";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";

const { RangePicker } = DatePicker;

const TimeFilter = ({
  label = "Time",
  onChange,
  width = "auto",
  defaultValue,
}) => {
  const handleChange = React.useCallback(
    (_, range) => {
      let res = null;
      if ((range || []).every(Boolean)) {
        res = { from: format(range[0]), to: format(range[1], true) };
      }
      onChange(res);
    },
    [onChange],
  );

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <label>{label}</label>
      <RangePicker
        onCalendarChange={handleChange}
        showTime
        style={{ width }}
        defaultValue={defaultValue}
        presets={[
          { label: "Today", value: [dayjs().startOf("d"), dayjs().endOf("d")] },
          {
            label: "Yesterday",
            value: [
              dayjs().add(-1, "d").startOf("d"),
              dayjs().add(-1, "d").endOf("d"),
            ],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().add(-7, "d").startOf("d"), dayjs().endOf("d")],
          },
          {
            label: "This Month",
            value: [dayjs().startOf("m").startOf("d"), dayjs().endOf("d")],
          },
          {
            label: "Last Month",
            value: [
              dayjs().add(-1, "month").startOf("d"),
              dayjs().startOf("m").startOf("d").add(-1, "ms"),
            ],
          },
        ]}
      />
    </Space>
  );
};

const format = (time, isTo) => {
  let suffix = isTo ? "999" : "000";
  return time.replace(/\s/, "T") + `.${suffix}Z`;
};

export default TimeFilter;
