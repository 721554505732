import { ApolloProvider, gql } from "@apollo/client";
import { App as AppAntd, ConfigProvider } from "antd";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { apolloClient } from "./client";
import MainComp from "./components/main";
import { RouteChangeProvider } from "./components/route-change";
import router from "./constants/router";
import { AppProvider } from "./context";
import { UserFrag } from "./graphql/fragment";
import NotFound from "./pages/notfound";
import token from "./theme/token.json";
import { getTokenObject, setTokenObject } from "./utils";

function App() {
  const authenticate = React.useCallback((obj) => {
    setTokenObject(obj);

    setState({ currentUser: obj?.user });
  }, []);

  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    currentUser: null,
    loading: true,
    authenticate,
  });

  React.useEffect(() => {
    const token = getTokenObject();
    if (token) {
      setState({ loading: true });
      apolloClient
        .query({
          query: gql`
            query me {
              me {
                ...UserFrag
              }
            }
            ${UserFrag}
          `,
        })
        .then((res) => {
          if (res?.data?.me) {
            setState({ currentUser: res.data.me });
          }
        })
        .catch((_err) => {
          setTokenObject(null);
        })
        .finally(() => {
          setState({ loading: false });
        });
    } else {
      setState({ loading: false });
    }
  }, []);

  return (
    <BrowserRouter>
      <ConfigProvider theme={{ token }}>
        <ApolloProvider client={apolloClient}>
          <AppAntd style={{ backgroundColor: "rgba(241, 242, 244, 1)" }}>
            <AppProvider {...state}>
              <RouteChangeProvider>
                <Routes>
                  {router.map((r, idx) => {
                    const el = r.element;
                    const isCustom = !!r.cutomLayout;
                    const Wrapp = isCustom ? React.Fragment : MainComp;

                    return (
                      <Route
                        key={r.path + idx}
                        path={r.path}
                        element={<Wrapp>{el}</Wrapp>}
                      />
                    );
                  })}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </RouteChangeProvider>
            </AppProvider>
          </AppAntd>
        </ApolloProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
