import { useQuery } from "@apollo/client";
import { Card, Row, Space, Typography } from "antd";
import React from "react";
import Suspense from "../../../components/shared/suspense";
import { TrackingHistoryProvider } from "../../../components/tracking/context";
import { EXPORT_AMAZON_TRACKING_HISTORY } from "../../../graphql/query";

const TrackingHistoryFilter = React.lazy(() =>
  import("../../../components/tracking/historyFilter"),
);
const TrackingHistoryTable = React.lazy(() =>
  import("../../../components/tracking/historyDataTable"),
);

export default function ExportTracking() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      storeId: null,
      authorId: null,
    }),
    [],
  );

  const [filter, setFilter] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(
    EXPORT_AMAZON_TRACKING_HISTORY,
    {
      variables: {
        filter,
      },
    },
  );

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="Export Tracking History" />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Suspense>
          <TrackingHistoryFilter
            onChange={handleFilterChange}
            filter={filter}
            handleClearFilter={handleClearFilter}
          />
        </Suspense>
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <TrackingHistoryProvider refetch={refetch}>
              <Suspense>
                <TrackingHistoryTable
                  loading={loading}
                  total={data?.exportAmazonTrackingHistory?.total || 0}
                  data={data?.exportAmazonTrackingHistory?.nodes || []}
                  handlePaginationChange={handlePaginationChange}
                  filter={filter}
                />
              </Suspense>
            </TrackingHistoryProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
