import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import React from "react";
import { useNavigate } from "react-router-dom";
import { invalidArr, invalidOb } from "../../utils";
import Suspense from "../shared/suspense";

const BaseSelect = React.lazy(() => import("../base/baseSelect"));
const PrintFiles = React.lazy(() => import("./printFiles"));
const MediaButton = React.lazy(() => import("../shared/media-button"));
const Personalized = React.lazy(() => import("./personalized"));
const PersonalizedCard = React.lazy(() => import("./personalizedCard"));
const ProductVariants = React.lazy(() => import("./productVariants"));

const ProductForm = ({ onFinish, loading, value, name }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const fieldsRef = React.useRef();

  React.useEffect(() => {
    if (invalidOb(value)) return;
    const { fields, images, productBases, variants, designPositions } = value;
    const newFields = (fields || []).map((i) => ({
      name: i.title,
      type: i.type,
      key: `field-${i.sorting}`,
    }));

    const newImages = (images || []).map((i) => i.file).filter(Boolean);
    const bases = (productBases || []).map((i) => i.id).filter(Boolean);
    const newVars = (variants || [])
      .map((i) => {
        if (invalidOb(i)) return null;
        const attributes = get(i, "productBaseVariant.attributes");
        return {
          attributes,
          id: i.id,
          sorting: i.sorting,
          salePrice: i.salePrice,
          regularPrice: i.regularPrice,
          productBaseVariantId: i.productBaseVariantId,
        };
      })
      .filter(Boolean);
    const newDP = (designPositions || [])
      .map((i) => {
        if (invalidOb(i)) return null;
        const file = i.url
          ? [{ url: i.url }]
          : !invalidOb(i.file)
          ? [i.file]
          : [];

        return {
          ...i.designPosition,
          designPositionId: i?.designPosition?.id,
          id: i.id,
          file,
        };
      }, [])
      .filter(Boolean);

    form.setFieldsValue({
      title: value.title,
      description: value.description,
      personalized: !!value.personalized,
      psd: value.psd,
      psdFiles: value.psdFiles,
      fields: newFields,
      images: newImages,
      productBases: bases,
      variants: newVars,
      designPositions: newDP,
    });
  }, [value, form]);

  const handleBaseChange = React.useCallback(
    (_value, option) => {
      form.resetFields(["variants", "designPositions"]);

      const variants = get(option, "data.variants");
      const designPositions = get(option, "data.designPositions");
      form.setFieldsValue({
        designPositions,
        variants: formatVariants(variants),
        changeBase: !form.getFieldValue("changeBase"),
      });
    },
    [form],
  );

  const handleVariantChange = React.useCallback(() => {
    form.setFieldsValue({
      updateVar: !form.getFieldValue("updateVar"),
    });
  }, [form]);

  const handleFinish = React.useCallback(
    (values) => {
      let fields = fieldsRef.current?.getFields?.();
      fields = (fields || []).map((i) => ({ title: i?.name, type: i?.type }));

      const psdFiles = (values.psdFiles || []).map((i) => i.id).filter(Boolean);
      const oldImages = get(value, "images");
      const mapImgs = (oldImages || []).reduce((acc, cur) => {
        if (invalidOb(cur)) return acc;
        const fileId = get(cur, "file.id");
        if (!fileId) return acc;
        acc[fileId] = cur;
        return acc;
      }, {});

      const images = (values.images || [])
        .map((i, idx) => {
          
          let file = i;
          if (file.file) {
            while (file.file) {
              file = file.file
            }
          }
          const matchImg = mapImgs[file.id];

          return {
            id: matchImg?.id ?? undefined,
            fileId: file.id,
            productBaseId:
              Array.isArray(values.productBases) &&
              values.productBases?.length > 0
                ? values.productBases[0]
                : values.productBases,
            isMain: !idx,
          };
        })
        .filter(Boolean);

      const variants = (values.variants || []).map((i) => ({
        productBaseVariantId: i.productBaseVariantId,
        regularPrice: i.regularPrice,
        salePrice: i.salePrice,
        id: i.id ?? undefined,
      }));
      const designPositions = (values.designPositions || [])
        .map((i) => {
          if (invalidOb(i)) return null;
          const fileId = get(i, "file[0].id");
          return {
            id: !!value ? i.id : undefined,
            designPositionId: !!value ? i.designPositionId : i.id,
            fileId,
          };
        })
        .filter(Boolean);
      const bases = Array.isArray(values.productBases)
        ? values.productBases
        : [values.productBases];

      const input = {
        title: values.title,
        description: values.description,
        images: images.length > 0 ? images : undefined,
        productBases: bases,
        variants: variants.length > 0 ? variants : undefined,
        designPositions:
          designPositions.length > 0 ? designPositions : undefined,
        personalized: values.personalized,
        fields,
        psd: values.psd,
        psdFiles: psdFiles.length > 0 ? psdFiles : undefined,
      };

      onFinish?.(input);
    },
    [onFinish, value],
  );

  return (
    <Form
      form={form}
      name={name}
      layout="vertical"
      onFinish={handleFinish}
      style={{ marginTop: 16 }}
      initialValues={{
        title: "",
        description: "",
        productBases: [],
        variants: [],
        designPositions: [],
        fields: [],
        personalized: false,

        // Internal
        changeBase: false,
        updateVar: false,
      }}
    >
      <Space direction="vertical" style={{ display: "flex" }} size="middle">
        <Card title="General">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input placeholder="Enter product title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter product description" rows={4} />
          </Form.Item>
          <Suspense>
            <Form.Item
              name="productBases"
              label="Product Bases"
              rules={[{ required: true, message: "Product base is required" }]}
            >
              <BaseSelect onChange={handleBaseChange} disabled={!!value} />
            </Form.Item>
          </Suspense>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="images"
                label="Images"
                rules={[{ required: true, message: "Images is required" }]}
              >
                <MediaButton
                  accept="image/*"
                  listType="picture-card"
                  multiple
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="psdFiles" label="Design Resource Files">
                <MediaButton
                  accept="image/*"
                  listType="picture-card"
                  multiple
                />
              </Form.Item>
              <Form.Item name="psd" label="Design Resource URL">
                <Input.TextArea
                  placeholder="Enter design resource url"
                  rows={3}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) =>
            !isEqual(prev.changeBase, cur.changeBase) ||
            !isEqual(prev.updateVar, cur.updateVar)
          }
        >
          {() => (
            <Suspense>
              <Form.Item name="variants">
                <ProductVariants onChange={handleVariantChange} />
              </Form.Item>
            </Suspense>
          )}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) =>
            !isEqual(prev.changeBase, cur.changeBase) ||
            !isEqual(prev.updateVar, cur.updateVar)
          }
        >
          {() => (
            <Suspense>
              <Form.Item name="designPositions">
                <PrintFiles />
              </Form.Item>
            </Suspense>
          )}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) =>
            !isEqual(prev.personalized, cur.personalized) ||
            !isEqual(prev.fields, cur.fields)
          }
        >
          {({ getFieldValue }) => {
            const fields = getFieldValue("fields");
            return (
              <Suspense>
                <PersonalizedCard>
                  <Personalized ref={fieldsRef} fields={fields} />
                </PersonalizedCard>
              </Suspense>
            );
          }}
        </Form.Item>

        <Row justify="end" style={{ marginTop: 20 }}>
          <Space>
            <Button children="Cancel" onClick={() => navigate(-1)} />
            <Button
              type="primary"
              htmlType="submit"
              children="Submit"
              loading={loading}
            />
          </Space>
        </Row>
      </Space>
    </Form>
  );
};

const formatVariants = (arr) => {
  if (invalidArr(arr)) return arr;

  return arr
    .map((item) => {
      if (invalidOb(item)) return null;
      return {
        id: null,
        productBaseVariantId: item.id,
        regularPrice: item.regularPrice,
        salePrice: item.salePrice,
        attributes: item.attributes,
        sorting: item.sorting,
      };
    })
    .filter(Boolean);
};

export default ProductForm;
