import React from "react";
const OrderContext = React.createContext(null);

const OrderContextProvider = ({ children, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bag = React.useMemo(() => ({ ...props }), Object.values(props));

  return <OrderContext.Provider value={bag}>{children}</OrderContext.Provider>;
};

const useOrderContext = () => {
  return React.useContext(OrderContext);
};

export { OrderContextProvider, useOrderContext };
