import { gql } from '@apollo/client';
import { StoreFrag } from '../fragment';

const CREATE_STORE = gql`
  mutation createStore($input: NewStore!) {
    createStore(input: $input) {
      ...StoreFrag
    }
  }
  ${StoreFrag}
`;

const UPDATE_STORE = gql`
  mutation updateStore($input: EditStore!) {
    updateStore(input: $input) {
      ...StoreFrag
    }
  }
  ${StoreFrag}
`;

const DELETE_STORE = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

export { CREATE_STORE, UPDATE_STORE, DELETE_STORE };