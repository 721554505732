import { useQuery } from "@apollo/client";
import { Button, Card, Row, Space, Typography } from "antd";
import React from "react";
import LinkComp from "../../components/link";
import {
  TableUser,
  UserContextProvider,
  UserFilter,
} from "../../components/user";
import { USERS } from "../../graphql/query";

export default function UserPage() {
  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      status: null,
      roles: null,
    }),
    [],
  );
  const [filter, setFilter] = React.useReducer(
    (s, p) => ({ ...s, ...p }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(USERS, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Members" />
        <Button
          children={
            <LinkComp children="Create Member" href="/members/create" />
          }
          type="primary"
        />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <UserFilter
          onChange={handleFilterChange}
          handleClearFilter={handleClearFilter}
        />
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <UserContextProvider refetch={refetch}>
              <TableUser
                data={data?.users?.nodes}
                total={data?.users?.total || 0}
                loading={loading}
                handlePaginationChange={handlePaginationChange}
                filter={filter}
              />
            </UserContextProvider>
          )}
        </Card>
      </Space>
    </>
  );
}
