import { useQuery } from "@apollo/client";
import { Checkbox, Col, Row, Skeleton, Spin } from "antd";
import React from "react";
import ReactInfiniteScroller from "react-infinite-scroller";
import styled from "styled-components";
import { FILES } from "../../../graphql/query";
import FileDetail from "./fileDetail";

const ImageTypes = ["image/png", "image/jpeg", "image/gif"];

const Library = ({ value, onChange, multiple }) => {
  const [filter] = React.useReducer((p, s) => ({ ...p, ...s }), {
    limit: 20,
    paged: 1,
    search: "",
  });

  const { data, loading, error, fetchMore } = useQuery(FILES, {
    variables: {
      filter,
    },
  });

  const handleLoadMore = React.useCallback(() => {
    if (!data || !data.files || !data.files.node) return;
    if (data.files.nodes.length < data.files.total) {
      fetchMore({
        variables: {
          filter: {
            ...filter,
            offset: data.files.nodes.length,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            files: {
              ...prev.files,
              nodes: [...prev.files.nodes, ...fetchMoreResult.files.nodes],
            },
          };
        },
      });
    }
  }, [data, filter, fetchMore]);

  const handleSelect = React.useCallback(
    (file) => () => {
      let newValue;
      if (value.find((v) => v.id === file.id)) {
        newValue = value.filter((v) => v.id !== file.id);
      } else {
        if (multiple) {
          newValue = [...value, file];
        } else {
          newValue = [file];
        }
      }
      onChange?.(newValue);
    },
    [value, multiple, onChange],
  );

  const isImage = React.useCallback((file) => {
    return ImageTypes.includes(file.mimeType);
  }, []);

  const len = value?.length;

  return (
    <Row gutter={15} style={{ display: "flex" }}>
      <Col span={18} style={{ paddingTop: 16 }}>
        {loading ? (
          <Spin size="small" />
        ) : error ? (
          <div>Error: {error?.toString()}</div>
        ) : (
          <div style={{ height: 620, overflow: "auto" }}>
            <ReactInfiniteScroller
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={data.files.nodes.length < data.files.total}
              loader={
                <div className="loader" key={0}>
                  <Skeleton active />
                </div>
              }
              useWindow={false}
            >
              <div className={"files-container"}>
                {data.files.nodes.map((file, index) => (
                  <div
                    onClick={handleSelect(file)}
                    title={file.name}
                    key={index}
                    className={"file"}
                  >
                    <div
                      style={{
                        visibility: value.find((v) => v.id === file.id)
                          ? "visible"
                          : "hidden",
                      }}
                      className={"check"}
                    >
                      <Checkbox checked />
                    </div>
                    {isImage(file) ? (
                      <Thumbnail
                        url={file.thumbnailUrl ? file.thumbnailUrl : file.url}
                      />
                    ) : (
                      <div className="picture-outlined">
                        <span>
                          {file && file.name
                            ? file.name.split(".").pop()
                            : null}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </ReactInfiniteScroller>
          </div>
        )}
      </Col>
      <Col
        style={{
          borderLeft: "1px solid #e2e8f0",
          padding: 0,
          margin: 0,
        }}
        span={6}
      >
        {len > 0 && (
          <div className={"inner"}>
            {value.length > 0 && (
              <FileDetail
                onChange={(file, client) => {
                  value[len - 1] = file;
                  onChange(value);
                  // this.handleUpdateCache(file, client);
                }}
                onDeleted={(file, client) => {
                  value.splice(len - 1, 1);
                  onChange(value);
                  // this.handleWriteCache(file, client);
                }}
                isImage={isImage(value[len - 1])}
                value={value[len - 1]}
              />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

const Thumbnail = styled.div`
  width: 150px;
  height: 150px;
  background: url("${(props) => props.url}");
  background-size: cover;
  cursor: pointer;
`;

export default Library;
