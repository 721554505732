import { Layout } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../components/header";
import SideBarComp from "../../components/sidebar";
import { useAppContext } from "../../context";
import ProgressBar from "../progress-bar";
import { useRouteChange } from "../route-change";
import Suspense from "../shared/suspense";
import Spinner from "../spinner";

const { Content } = Layout;

export default function MainComp({ children }) {
  const { loading, currentUser } = useAppContext();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  useRouteChange({
    onRouteChangeStart: () => {
      setOpen(true);
    },
    onRouteChangeComplete: () => {
      setOpen(false);
    },
  });

  React.useEffect(() => {
    if (!loading) {
      if (!currentUser) {
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, currentUser]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <HeaderComp />
          <SideBarComp />
          <Layout
            style={{
              padding: 20,
              marginLeft: !!currentUser ? 230 : 0,
              backgroundColor: "rgba(241, 242, 244, 1)",
              minHeight: "100vh",
            }}
          >
            <Content style={{ overflow: "initial" }}>
              <Suspense fallback={<Spinner />}>{children}</Suspense>
            </Content>
          </Layout>
        </>
      )}
      {open && <ProgressBar />}
    </>
  );
}
