import { gql } from '@apollo/client';

// Without fulfillment
const FulfillmentConfigFrag2 = gql`
  fragment FulfillmentConfigFrag2 on FulfillmentConfig {
    id
    apiKey
    apiSecret
    description
  }
`;

const FulfillmentFrag = gql`
  fragment FulfillmentFrag on Fulfillment {
    id
    name
    slug
    fulfillmentConfigs {
      ...FulfillmentConfigFrag2
    }
  }
  ${FulfillmentConfigFrag2}
`;

const FulfillmentConfigFrag = gql`
  fragment FulfillmentConfigFrag on FulfillmentConfig {
    id
    fulfillmentId
    apiKey
    apiSecret
    description
    fulfillment {
      ...FulfillmentFrag
    }
  }
  ${FulfillmentFrag}
`;

const FulfillmentProductFrag = gql`
  fragment FulfillmentProductFrag on  FulfillmentProduct {
    id
    title
    originId
  }
`;

export {
  FulfillmentFrag,
  FulfillmentConfigFrag,
  FulfillmentConfigFrag2,
  FulfillmentProductFrag
}