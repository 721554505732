import { ROLES } from "../../../constants";
import { Col, Form, Input, Radio, Row, Select, Space } from "antd";
import React from "react";

export default function UserForm({
  footer = null,
  isRegister,
  onFinish,
  value,
  textAlign,
  fromProfile,
}) {
  const [form] = Form.useForm();

  const roles = React.useMemo(() => {
    return Object.entries(ROLES).map(([label, value]) => ({ label, value }));
  }, []);

  React.useEffect(() => {
    if (value) {
      const { fullName, email, roles, phone, address, status } = value;
      form.setFieldsValue({
        fullName,
        email,
        roles,
        phone,
        address,
        status,
      });
    }
  }, [value, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      wrapperCol={{ style: { minWidth: 250 } }}
      initialValues={{
        roles: ROLES.Admin,
      }}
    >
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="Fullname"
            rules={genRules(
              [
                {
                  required: true,
                  message: "Please input your Fullname",
                },
              ],
              isRegister,
            )}
          >
            <Input placeholder="Fullname" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={genRules(
              [
                {
                  required: true,
                  message: "Please input your Email",
                },
              ],
              isRegister,
            )}
          >
            <Input placeholder="Email" autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      {!!value || isRegister ? null : (
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              hasFeedback
              rules={genRules(
                [
                  {
                    required: true,
                    message: "Please input your Password",
                  },
                ],
                isRegister,
              )}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={genRules(
                [
                  {
                    required: true,
                    message: "Please confirm your Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The new password not match"),
                      );
                    },
                  }),
                ],
                isRegister,
              )}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={18}>
        {isRegister ? (
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              hasFeedback
              rules={genRules(
                [
                  {
                    required: true,
                    message: "Please input your Password",
                  },
                ],
                isRegister,
              )}
            >
              <Input.Password placeholder="Enter password"/>
            </Form.Item>
          </Col>
        ) : null}
        <Col span={isRegister ? 12 : 24}>
          <Form.Item
            name="roles"
            label="Role"
            rules={genRules(
              [
                {
                  required: true,
                  message: "Please choose role",
                },
              ],
              isRegister,
            )}
          >
            <Select options={roles} disabled={!!fromProfile} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item name="phone" label="Phone">
            <Input placeholder="Phone" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="address" label="Address">
            <Input placeholder="Address" />
          </Form.Item>
        </Col>
      </Row>

      {!!value && !fromProfile && (
        <Form.Item name="status" label="Status">
          <Radio.Group>
            <Space direction="vertical">
              {[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
              ].map(({ value, label: children }) => (
                <Radio value={value} key={value} children={children} />
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}
      <Row align="middle" justify="center">
        <Col span={24} style={{ textAlign: textAlign || "center" }}>
          {footer}
        </Col>
      </Row>
    </Form>
  );
}

// Utils
const genRules = (rules, condition) => {
  return condition ? rules : null;
};
