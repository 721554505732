import { useQuery } from "@apollo/client";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FULFILLMENT } from "../../constants";
import { useAppContext } from "../../context";
import { FULFILLMENTS } from "../../graphql/query";
import { invalidOb } from "../../utils";
import MediaButton from "../shared/media-button";
import Suspense from "../shared/suspense";
import {
  fetchFulfillmentProductVariants,
  formatDataForCC,
  formatInputAttrs,
  formatInputDS,
  formatInputVars,
  formatProductsForGM,
  formatValAttrs,
  getCombineVariants,
  invalidFFProductId,
} from "./util";

const Attributes = React.lazy(() => import("./attributes"));
const Variants = React.lazy(() => import("./variants"));
const DesignPositions = React.lazy(() => import("./designPositions"));
const ProductFulfillment = React.lazy(() => import("./productFulfillment"));
const FetchVariantsMissing = React.lazy(() => import("./fetchVariantsMissing"));
const DesignPlacementPresets = React.lazy(() =>
  import("./designPlacementPresets"),
);

export function BaseForm({ name, value, onFinish, loading }) {
  const { notification } = useAppContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //
  const { data } = useQuery(FULFILLMENTS);

  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    fulfillments: [],
    customcatID: null,
    gearmentID: null,
    customID: null,
  });

  React.useEffect(() => {
    const nodes = data?.fulfillments || [];

    const ffs = nodes.reduce((acc, item) => {
      if (invalidOb(item)) return acc;
      acc[item.slug] = {
        value: item.id,
        label: item.name,
        slug: item.slug,
      };
      return acc;
    }, {});

    const state = {
      customcatID: ffs[FULFILLMENT.Customcat.slug]?.value,
      gearmentID: ffs[FULFILLMENT.Gearment.slug]?.value,
      customID: ffs[FULFILLMENT.Custom.slug]?.value,
    };

    form.setFieldValue("fulfillmentId", state.customcatID);
    setState({ fulfillments: Object.values(ffs), ...state });
  }, [data, form]);

  const { formatDataToFF, SPECIAL_IDS } = React.useMemo(() => {
    return {
      formatDataToFF: {
        [state.customcatID]: formatDataForCC,
        [state.gearmentID]: formatProductsForGM,
      },
      SPECIAL_IDS: [state.customcatID, state.gearmentID],
    };
  }, [state.customcatID, state.gearmentID]);

  React.useEffect(() => {
    if (invalidOb(value)) return;

    const { attributes, image } = value;
    const images = invalidOb(image) ? [] : [image];
    form.setFieldsValue({
      title: get(value, "title") || "",
      images,
      variants: get(value, "variants"),
      fulfillmentId: get(value, "fulfillment.fulfillmentId"),
      productId: get(value, "fulfillment.productId"),
      presetId: get(value, "fulfillment.presetId"),
      attributes: formatValAttrs(attributes),
      designPositions: get(value, "designPositions"),
    });
  }, [value, form]);

  const handleChange = React.useCallback(
    (changedValues, values) => {
      if ("fulfillmentId" in changedValues) {
        form.resetFields([
          "productId",
          "presetId",
          "attributes",
          "variants",
          "designPositions",
        ]);

        if (values.fulfillmentId === state.customID) {
          form.setFieldValue("designPositions", DESIGN_POSITIONS_DEFAULT);
        }
      }

      if ("productId" in changedValues) {
        // Fetch product variant
        (async () => {
          form.setFieldValue("loadingVar", true);
          try {
            const result = await fetchFulfillmentProductVariants(
              changedValues.productId,
              values.fulfillmentId,
            );
            if (result) {
              const {
                variants = [],
                attributes = [],
                designPositions = [],
                title,
              } = formatDataToFF[values.fulfillmentId]?.(result) || {};

              form.setFieldsValue({
                loadingVar: false,
                title,
                variants,
                attributes,
                designPositions:
                  designPositions?.length > 0
                    ? designPositions
                    : DESIGN_POSITIONS_DEFAULT,
              });
            }
          } catch (err) {
            notification.error({ message: err?.toString() });
          } finally {
            form.setFieldsValue({
              loadingVar: false,
            });
          }
        })();
      }
    },
    [form, state.customID, formatDataToFF, notification],
  );

  const handleAttributeChange = React.useCallback(
    (attributes) => {
      const oldVariants = form.getFieldValue("variants");
      const validAttributes = [];
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        if (attribute.name && attribute.slug && attribute.options.length) {
          validAttributes.push(attribute);
        }
      }

      const variants = getCombineVariants(validAttributes, oldVariants);

      form.setFieldsValue({
        variants,
        updateAttr: !form.getFieldValue("updateAttr"),
      });
    },
    [form],
  );

  const handleVariantChange = React.useCallback(() => {
    form.setFieldsValue({
      updateVar: !form.getFieldValue("updateVar"),
    });
  }, [form]);

  const handleDesignPositionChange = React.useCallback(() => {
    form.setFieldsValue({
      updateDS: !form.getFieldValue("updateDS"),
    });
  }, [form]);

  const handleMissingVariantChange = React.useCallback(
    (newVariants, attributes) => {
      form.setFieldsValue({
        variants: newVariants,
        attributes,
      });
    },
    [form],
  );

  const handleFinish = React.useCallback(
    (values) => {
      const {
        attributes,
        fulfillmentId,
        productId,
        images,
        variants,
        designPositions,
        title,
        presetId,
      } = values;
      const newAttrs = formatInputAttrs(attributes);
      const newDS = formatInputDS(designPositions);
      const newVars = formatInputVars(variants);

      const fulfillment = { fulfillmentId, productId, presetId };
      const [img] = (images || []).filter(Boolean);
      const imageId = img ? img.id : undefined;

      const isFFSpecial = SPECIAL_IDS.includes(fulfillmentId);
      if (isFFSpecial) {
        const invalid = invalidFFProductId(variants);
        if (invalid) {
          notification.error({
            message: "You must enter fulfillment product id!",
          });
          return;
        }
      }

      const input = {
        regularPrice: 0,
        salePrice: 0,
        baseCost: 0,

        title,
        imageId,
        fulfillment,
        attributes: newAttrs,
        variants: newVars,
        designPositions: newDS,
      };

      onFinish?.(input);
    },
    [onFinish, SPECIAL_IDS, notification],
  );

  return (
    <Form
      layout="vertical"
      form={form}
      name={name}
      onFinish={handleFinish}
      onValuesChange={handleChange}
      style={{ marginTop: 16 }}
      initialValues={{
        fulfillmentId: state.customcatID ?? null,
        productId: null,
        attributes: [],
        variants: [],
        designPositions: [],
        images: [],

        // Internal
        updateAttr: false,
        updateVar: false,
        updateDS: false,
      }}
    >
      <Space direction="vertical" style={{ display: "flex" }} size="middle">
        <Card title="General">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Base title is required" }]}
          >
            <Input placeholder="Enter base title" />
          </Form.Item>
          <Row gutter={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, cur) =>
                !isEqual(prev.fulfillmentId, cur.fulfillmentId)
              }
            >
              {({ getFieldValue }) => {
                const fulfillmentId = getFieldValue("fulfillmentId");
                const isCutomcat = fulfillmentId === state.customcatID;
                const isCustom = fulfillmentId === state.customID;
                return (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="fulfillmentId"
                        label="Fulfillment"
                        rules={[
                          {
                            required: true,
                            message: "Fulfillment is required",
                          },
                        ]}
                      >
                        <Select options={state.fulfillments} />
                      </Form.Item>
                    </Col>
                    {isCustom ? null : (
                      <>
                        <Col span={isCutomcat ? 6 : 12}>
                          <Suspense>
                            <ProductFulfillment fulfillmentId={fulfillmentId} />
                            {!!value && (
                              <FetchVariantsMissing
                                value={value}
                                formatDataToFF={formatDataToFF}
                                onChange={handleMissingVariantChange}
                              />
                            )}
                          </Suspense>
                        </Col>
                        <Col span={isCutomcat ? 6 : 0}>
                          <Suspense>
                            <DesignPlacementPresets />
                          </Suspense>
                        </Col>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Form.Item name="images" label="Images">
            <MediaButton accept="image/*" listType="picture" />
          </Form.Item>
        </Card>
        <Card title="Variants">
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) => {
              // Update box `Variants` when change attribute.
              return (
                !isEqual(prev.updateAttr, cur.updateAttr) ||
                !isEqual(prev.loadingVar, cur.loadingVar)
              );
            }}
          >
            {({ getFieldValue }) => {
              const loading = getFieldValue("loadingVar");
              return (
                <Suspense>
                  <Form.Item name="attributes">
                    <Attributes
                      onChange={handleAttributeChange}
                      loading={loading}
                    />
                  </Form.Item>
                </Suspense>
              );
            }}
          </Form.Item>
        </Card>
        <Form.Item
          noStyle
          shouldUpdate={(prev, cur) =>
            !isEqual(prev.updateVar, cur.updateVar) ||
            !isEqual(prev.loadingVar, cur.loadingVar) ||
            !isEqual(prev.fulfillmentId, cur.fulfillmentId)
          }
        >
          {({ getFieldValue }) => {
            const loading = getFieldValue("loadingVar");
            const fulfillmentId = getFieldValue("fulfillmentId");
            const isCustomFF = fulfillmentId === state.customID;

            return (
              <Suspense>
                <Form.Item name="variants">
                  <Variants
                    onChange={handleVariantChange}
                    loading={loading}
                    isCustomFF={isCustomFF}
                  />
                </Form.Item>
              </Suspense>
            );
          }}
        </Form.Item>
        <Card title="Design Position" bodyStyle={{ padding: "1px 0px 0px" }}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) =>
              !isEqual(prev.updateDS, cur.updateDS) ||
              !isEqual(prev.updateVar, cur.updateVar) ||
              !isEqual(prev.loadingVar, cur.loadingVar)
            }
          >
            {({ getFieldValue }) => {
              const variants = getFieldValue("variants");
              const loading = getFieldValue("loadingVar");
              return (
                <Suspense>
                  <Form.Item name="designPositions">
                    <DesignPositions
                      onChange={handleDesignPositionChange}
                      form={form}
                      variants={variants}
                      loading={loading}
                    />
                  </Form.Item>
                </Suspense>
              );
            }}
          </Form.Item>
        </Card>
        <Row justify="end" style={{ marginTop: 20 }}>
          <Space>
            <Button children="Cancel" onClick={() => navigate(-1)} />
            <Button
              type="primary"
              htmlType="submit"
              children="Submit"
              loading={loading}
            />
          </Space>
        </Row>
      </Space>
    </Form>
  );
}

const DESIGN_POSITIONS_DEFAULT = [
  {
    description: "5000x5000px|PNG|300DPI",
    image: null,
    name: "Default",
  },
];
