import { gql } from "@apollo/client";
import { FileFrag } from "../fragment";

const FILES = gql`
  query files($filter: FileFilter!) {
    files(filter: $filter) {
      total
      nodes {
        ...FileFrag
      }
    }
  }
  ${FileFrag}
`;

const FILE_BY_ID = gql`
  query fileById($id: ID!) {
    fileById(id: $id) {
      ...FileFrag
    }
  }
  ${FileFrag}
`;

export { FILES, FILE_BY_ID };
