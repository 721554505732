import Fulfillments from "../pages/fulfillments";
import HomePage from "../pages/home";
import LoginPage from "../pages/login";
import MemberPage from "../pages/members";
import CreateMemberPage from "../pages/members/create";
import OrdersPage from "../pages/orders";
import CreateOrderPage from "../pages/orders/create-manual";
import ExportTrackingPage from "../pages/orders/export-tracking";
import ExportTrackingHistoryPage from "../pages/orders/export-tracking-history";
import MappingOrdersPage from "../pages/orders/mapping";
import ProductBasesPage from "../pages/product-bases";
import CreateProductBasePage from "../pages/product-bases/create";
import EditProductBasePage from "../pages/product-bases/edit";
import ProductsPage from "../pages/products";
import CreateProductPage from "../pages/products/create";
import EditProductPage from "../pages/products/edit";
import RegisterPage from "../pages/register";
import TasksPage from "../pages/tasks";
import StorePage from "../pages/stores";

const router = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    cutomLayout: true,
  },
  {
    path: "/register",
    element: <RegisterPage />,
    cutomLayout: true,
  },
  {
    path: "/fulfillments",
    element: <Fulfillments />,
  },
  {
    path: "/members",
    element: <MemberPage />,
  },
  {
    path: "/members/create",
    element: <CreateMemberPage />,
  },
  {
    path: "/orders",
    element: <OrdersPage />,
  },
  {
    path: "/orders/create-manual",
    element: <CreateOrderPage />,
  },
  {
    path: "/orders/export-tracking",
    element: <ExportTrackingPage />,
  },
  {
    path: "/orders/export-tracking-history",
    element: <ExportTrackingHistoryPage />,
  },
  {
    path: "/orders/mapping",
    element: <MappingOrdersPage />,
  },
  {
    path: "/product-bases",
    element: <ProductBasesPage />,
  },
  {
    path: "/product-bases/create",
    element: <CreateProductBasePage />,
  },
  {
    path: "/product-bases/:id",
    element: <EditProductBasePage />,
  },
  {
    path: "/products",
    element: <ProductsPage />,
  },
  {
    path: "/products/create",
    element: <CreateProductPage />,
  },
  {
    path: "/products/:id",
    element: <EditProductPage />,
  },
  {
    path: "/tasks",
    element: <TasksPage />,
  },
  {
    path: "/stores",
    element: <StorePage />,
  },
];

export default router;
