import { useQuery } from "@apollo/client";
import { Button, Card, Row, Space, Typography } from "antd";
import React from "react";
import {
  CreateStore,
  SotreFilter,
  StoreContextProvider,
  TableStore,
} from "../../components/store";
import { STORES } from "../../graphql/query";
import { useToggle } from "../../hooks";

export default function StorePage() {
  const [open, toggleOpen] = useToggle(false);

  const initialFilter = React.useMemo(
    () => ({
      paged: 1,
      limit: 20,
      search: "",
      status: null,
      platform: null,
    }),
    [],
  );
  const [filter, setFilter] = React.useReducer(
    (s, p) => ({ ...s, ...p }),
    initialFilter,
  );

  const { data, loading, error, refetch } = useQuery(STORES, {
    variables: {
      filter,
    },
  });

  const handleFilterChange = React.useCallback((value) => {
    setFilter({ ...value });
  }, []);

  React.useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    if (
      !searchParams.has("from") ||
      !["create", "update"].includes(searchParams.get("from"))
    )
      return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  const handlePaginationChange = React.useCallback((values) => {
    setFilter({ ...values });
  }, []);

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={1} children="All Stores" />
        <Button type="primary" children="Create Store" onClick={toggleOpen} />
      </Row>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <SotreFilter
          onChange={handleFilterChange}
          handleClearFilter={handleClearFilter}
        />
        <Card bodyStyle={{ padding: 0 }}>
          {error ? (
            <div>{error?.toString()}</div>
          ) : (
            <StoreContextProvider refetch={refetch}>
              <TableStore
                loading={loading}
                data={data?.stores?.nodes || []}
                total={data?.stores?.total || 0}
                handlePaginationChange={handlePaginationChange}
                filter={filter}
              />
            </StoreContextProvider>
          )}
        </Card>
      </Space>
      <CreateStore open={open} onCancel={toggleOpen} refetch={refetch} />
    </>
  );
}
