import { gql } from "@apollo/client";
import { ProductFrag } from "../fragment";

const CREATE_PRODUCT = gql`
  mutation createProduct($input: NewProduct!) {
    createProduct(input: $input) {
      ...ProductFrag
    }
  }
  ${ProductFrag}
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: EditProduct!) {
    updateProduct(input: $input) {
      ...ProductFrag
    }
  }
  ${ProductFrag}
`;

const DELETE_PRODUCTS = gql`
  mutation deleteProducts($ids: [ID!]!) {
    deleteProducts(ids: $ids)
  }
`;

const UPDATE_PRODUCT_DESIGN_RESOURCE = gql`
  mutation updateProductDesignResource($input: EditProductDesignResource) {
    updateProductDesignResource(input: $input)
  }
`;

const DELETE_PRODUCT_VARIANTS = gql`
  mutation deleteProductVariants($ids: [ID!]!) {
    deleteProductVariants(ids: $ids)
  }
`;

export {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCTS,
  UPDATE_PRODUCT_DESIGN_RESOURCE,
  DELETE_PRODUCT_VARIANTS,
};
