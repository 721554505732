import { gql } from '@apollo/client';

const FileFrag = gql`
  fragment FileFrag on File {
    id
    key
    name
    mimeType
    url
    thumbnailUrl
    thumbnail
    size
  }
`;

const FileUploadFrag = gql`
  fragment FileUploadFrag on FileUpload {
    key
    url
  }
`;

export { FileFrag, FileUploadFrag  };