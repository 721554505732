import { gql } from '@apollo/client';
import { UserFrag } from '../fragment';

const ME = gql`
  query me {
    me {
      ...UserFrag
    }
  }
  ${UserFrag}
`;

const USERS = gql`
  query users($filter: UserFilter!) {
    users(filter: $filter) {
      total
      nodes {
        ...UserFrag
      }
    }
  }
  ${UserFrag}
`;

const USER_BY_ID = gql`
  query userById($id: String!) {
    userById(id: $id) {
      ...UserFrag
    }
  }
  ${UserFrag}
`;

export { ME, USERS, USER_BY_ID };