import { gql } from '@apollo/client';
import { FileFrag } from '../fragment';

const CREATE_FILE = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      ...FileFrag
    }
  }
  ${FileFrag}
`;

const DELETE_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;

const CREATE_PRESIGNED_URL = gql`
  mutation createPresignedUrl($input: NewPresignedUrl!) {
    createPresignedUrl(input: $input) {
      key
      url
    }
  }
`;

export { CREATE_FILE, DELETE_FILE, CREATE_PRESIGNED_URL };