import { gql } from '@apollo/client';
import { FulfillmentConfigFrag } from '../fragment';

const CREATE_FULFILLMENT_CONFIGURE = gql`
  mutation createFulfillmentConfigure($input: NewFulfillmentConfig!) {
    createFulfillmentConfigure(input: $input) {
      ...FulfillmentConfigFrag
    }
  }
  ${FulfillmentConfigFrag}
`;

const DELETE_FULFILLMENT_CONFIGURE = gql`
  mutation deleteFulfillmentConfigure($fulfillmentId: String!) {
    deleteFulfillmentConfigure(fulfillmentId: $fulfillmentId)
  }
`;

const FETCH_FULFILLMENT_PRODUCTS = gql`
  mutation fetchFulfillmentProducts($fulfillmentId: String!) {
    fetchFulfillmentProducts(fulfillmentId: $fulfillmentId)
  }
`;

const FETCH_FULFILLMENT_PRODUCT_VARIANTS = gql`
  mutation fetchFulfillmentProductVariants($fulfillmentId: String!, $productId: String!) {
    fetchFulfillmentProductVariants(fulfillmentId: $fulfillmentId, productId: $productId)
  }
`;

export { CREATE_FULFILLMENT_CONFIGURE, DELETE_FULFILLMENT_CONFIGURE, FETCH_FULFILLMENT_PRODUCT_VARIANTS, FETCH_FULFILLMENT_PRODUCTS }