import Filter from "../filter";
import RadioFilter from "../filter/radio";
import MoreFilter from "../more-filter";
import { PLATFORM, STORE_STATUS } from "../../constants";
import { Card, Space } from "antd";
import React from "react";

const STATUS_OPTIONS = [
  { value: STORE_STATUS.Connected, label: STORE_STATUS.Connected },
  { value: STORE_STATUS.Suspended, label: STORE_STATUS.Suspended },
];

const PLATFORM_OPTIONS = [
  { value: PLATFORM.Amazon, label: PLATFORM.Amazon },
  { value: PLATFORM.Etsy, label: PLATFORM.Etsy },
];

export function SotreFilter({ onChange, handleClearFilter }) {
  const handleFilterChange = React.useCallback(
    (key) => (value) => {
      let newVal = [null, "all"].includes(value) ? null : value;
      onChange({ [key]: newVal });
    },
    [onChange],
  );

  const moreFilterMarkup = React.useMemo(() => {
    const children = (
      <Space direction="vertical" size="large">
        <RadioFilter
          label="Platform"
          onChange={handleFilterChange("platform")}
          options={PLATFORM_OPTIONS}
        />
        <RadioFilter
          label="Status"
          onChange={handleFilterChange("status")}
          options={STATUS_OPTIONS}
        />
      </Space>
    );

    return (
      <MoreFilter children={children} handleClearFilter={handleClearFilter} />
    );
  }, [handleFilterChange, handleClearFilter]);

  return (
    <Card>
      <Filter
        onChange={handleFilterChange("search")}
        moreFilter={moreFilterMarkup}
      />
    </Card>
  );
}
